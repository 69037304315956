import { Link } from "react-router-dom";
import Icon from "../Icon";

interface LinkProps {
  text?: string;
  linkTo: string
}

const LinkLine = (props: LinkProps) => {
  return (
    <>
      {props.linkTo.startsWith('http') ? (
        <div className="valign-wrapper">
          <Icon icon="open_in_new" />
          <a href={props.linkTo} target="_blank" rel="noreferrer">{props.text}</a>
        </div>
      ) : (
        <Link to={props.linkTo} >
          {props.text}
        </Link >
      )}
    </>
  )
}

export default LinkLine
