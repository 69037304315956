import { Col, ProgressBar, Row } from "react-bootstrap"
import { VictoryLabel, VictoryPie } from "victory"

interface AmpelProps {
}

const Ampel = (props: AmpelProps) => {
  const vollstaendigkeit = 77
  const data = [{ x: 1, y: vollstaendigkeit }, { x: 2, y: 100 - vollstaendigkeit }]

  return (
    <>
      <Row className="align-items-md-center" style={{ border: '0.25rem solid #138383', padding: '0rem' }}>
        <Col md={5}>
          <svg viewBox="0 0 200 200">
            <VictoryPie
              data={data}
              colorScale={['#198754', '#EFEFEF']}
              innerRadius={80}
              labels={() => ''}
              standalone={false}
              width={200}
              height={200}
            // style={{
            //   data: {
            //     fillOpacity: 1,
            //     stroke: '#d1d1d1',
            //     strokeWidth: 1
            //   }
            // }}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 24, fontFamily: 'ASAP' }}
              text={`${vollstaendigkeit}%`}
              x={100} y={90}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 16, fontFamily: 'ASAP' }}
              text={`vollständig`}
              x={100} y={110}
            />
          </svg>
        </Col>
        <Col md={6}>
          <ProgressBar style={{ height: '2rem', marginBottom: '0.25rem' }}>
            <ProgressBar now={100} variant="success" key={1} />
            <ProgressBar now={0} variant="grey" key={2} />
          </ProgressBar>
          <label>aktualisiert am 02.03.2023</label>
        </Col>
      </Row>
    </>
  )
}

export default Ampel
