import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { elements } from "../../../types/Transparenzbericht"
import CardContent from "../components/Card/CardContent"
import KontaktCard from "../components/Card/KontaktCard"
import ContentCard from "../components/ContentCard"

interface KontaktProps {
  data: elements.TransparenzXmlSpezialist | undefined;
}

const Kontakt = (props: KontaktProps) => {
  return (
    <>
      <ContentCard
        header="Standorte"
        collapsed
      >
        {props.data?.Weitere_Standorte?.standorte?.map((standort, index) =>
          <Row key={index} className="cardContentLine">
            <Col>
              <Link to={`/practice/1`}>{standort.Anmerkung}</Link>
            </Col>
            <Col md={3}>
              {index === 0 ? 'Praxisleitung' : 'Belegarzt'}
            </Col>
          </Row>
        )}
      </ContentCard>
      <ContentCard
        header="Persönlicher Kontakt"
        id="contactCard"
        data={props.data?.Spezialist?.Kontakt}
        collapsed
      >
        <KontaktCard kontakt={props.data?.Spezialist?.Kontakt} />
      </ContentCard>
      <ContentCard
        header="Soziale Medien"
        collapsed
      >
        {props.data?.Medienpraesenz?.medienpraesenz?.map((media, index) =>
          <CardContent key={index} type="Link" linkTo={media.URL} text={media.Kommentar} />
        )}
        {/* {props.data?.Soziale_Medien?.sozialemedia?.map((media, index) =>
              <CardContent key={index} type="Link" linkTo={media.URL} text={media.anmerkung} />
            )} */}
      </ContentCard>
    </>
  )
}

export default Kontakt
