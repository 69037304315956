export const lasar = {
  "Identifier" : "59666bd7-8c11-44f4-b183-52a705c754a9",
  "Einleitung" : {
    "vorhanden" : { },
    "Einleitungs_Einrichtung_Text" : {
      "vorhanden" : { },
      "text" : "Sehr geehrte Patientinnen und Patienten, sehr geehrte Kolleginnen und Kollegen, im Januar 2016 haben wir uns beruflich verändert und unsere chirurgische Tätigkeit auf den ambulanten Versorgungsbereich ausgeweitet. Die von uns gegründete Chirurgische Gemeinschaftspraxis LASAR liegt in unmittelbarer Nähe zum Evangelischen Stadtkrankenhaus Saarbrücken und ist als zertifiziertes Hernienzentrum zugelassen. Es werden gesetzlich und privat versicherte Patienten versorgt. Die Versorgung unserer stationären Patienten erfolgt weiterhin durch uns persönlich und in der gewohnten Qualität. Wir sind Belegärzte im Ev. Stadtkrankenhaus und führen dort gemeinsam die Chirurgische Belegabteilung."
    },
    "Aerztlicheleitung" : {
      "vorhanden" : { },
      "AerztlicheLeitung" : [ {
        "vorhanden" : { },
        "Titel" : "Dr.",
        "Anrede" : "Herr",
        "Vorname" : "Andreas",
        "Nachname" : "Lauer",
        "Profilbild" : {
          "vorhanden" : { },
          "URL" : "lauer.png",
          "Uuid" : "gfdgdfgdf",
          "Beschreibung" : "Beschreibung"
        },
        "Kontakt" : {
          "vorhanden" : { },
          "Telefon" : {
            "vorhanden" : { },
            "Rufnummer" : "068112441"
          },
          "Fax" : {
            "vorhanden" : { },
            "Rufnummer" : "068112442"
          },
          "EMail" : {
            "vorhanden" : { },
            "text" : "mail@mail.de"
          },
          "Homepage" : {
            "vorhanden" : { },
            "text" : "homepage"
          },
          "Mobil" : {
            "nichtVorhanden" : { }
          }
        }
      }, {
        "vorhanden" : { },
        "Titel" : "Dr.",
        "Anrede" : "Herr",
        "Vorname" : "Nihad",
        "Nachname" : "Sardoschau",
        "Profilbild" : {
          "vorhanden" : { },
          "URL" : "sardoschau.png",
          "Uuid" : "gfdgdfgdf",
          "Beschreibung" : "Beschreibung"
        },
        "Kontakt" : {
          "vorhanden" : { },
          "Telefon" : {
            "vorhanden" : { },
            "Rufnummer" : "068112441"
          },
          "Fax" : {
            "vorhanden" : { },
            "Rufnummer" : "068112442"
          },
          "EMail" : {
            "vorhanden" : { },
            "text" : "mail@mail.de"
          },
          "Homepage" : {
            "vorhanden" : { },
            "text" : "homepage"
          },
          "Mobil" : {
            "nichtVorhanden" : { }
          }
        }
      } ]
    },
    "Verwaltungsleitung" : {
      "vorhanden" : { },
      "Verwaltungsleitung" : [ {
        "vorhanden" : { },
        "Titel" : "Dr.",
        "Anrede" : "Herr",
        "Vorname" : "Andreas",
        "Nachname" : "Lauer",
        "Profilbild" : {
          "vorhanden" : { },
          "URL" : "lauer.png",
          "Uuid" : "gfdgdfgdf",
          "Beschreibung" : "Beschreibung"
        },
        "Kontakt" : {
          "vorhanden" : { },
          "Telefon" : {
            "vorhanden" : { },
            "Rufnummer" : "068112441"
          },
          "Fax" : {
            "vorhanden" : { },
            "Rufnummer" : "068112442"
          },
          "EMail" : {
            "vorhanden" : { },
            "text" : "mail@mail.de"
          },
          "Homepage" : {
            "vorhanden" : { },
            "text" : "homepage"
          },
          "Mobil" : {
            "nichtVorhanden" : { }
          }
        }
      }, {
        "vorhanden" : { },
        "Titel" : "Dr.",
        "Anrede" : "Herr",
        "Vorname" : "Nihad",
        "Nachname" : "Sardoschau",
        "Profilbild" : {
          "vorhanden" : { },
          "URL" : "sardoschau.png",
          "Uuid" : "gfdgdfgdf",
          "Beschreibung" : "Beschreibung"
        },
        "Kontakt" : {
          "vorhanden" : { },
          "Telefon" : {
            "vorhanden" : { },
            "Rufnummer" : "068112441"
          },
          "Fax" : {
            "vorhanden" : { },
            "Rufnummer" : "068112442"
          },
          "EMail" : {
            "vorhanden" : { },
            "text" : "mail@mail.de"
          },
          "Homepage" : {
            "vorhanden" : { },
            "text" : "homepage"
          },
          "Mobil" : {
            "nichtVorhanden" : { }
          }
        }
      } ]
    },
    "Pflegeleitung" : {
      "nichtVorhanden" : { }
    }
  },
  "Strukturdaten" : {
    "vorhanden" : { },
    "Leitung_Kurz" : {
      "vorhanden" : { },
      "Leitung" : "Lauer & Sardoschau"
    },
    "Name_Einrichtung" : {
      "vorhanden" : { },
      "Name" : "Lauer & Sardoschau"
    },
    "Adresse_Einrichtung" : {
      "vorhanden" : { },
      "adresse" : {
        "vorhanden" : { },
        "Strasse" : "Schmollerstraße",
        "Hausnummer" : "28",
        "Postleitzahl" : "66111",
        "Ort" : "Saarbrücken",
        "Bundesland" : "Saarland"
      }
    },
    "Mitgliedschaften" : {
      "vorhanden" : { },
      "Mitglied" : [ {
        "Name" : "DHG Deutsche Hernien Gesellschaft"
      }, {
        "Name" : "Chirurgische Gemeinschaftspraxis LASAR"
      } ]
    },
    "Anzahl_Mitarbeiter" : {
      "vorhanden" : { },
      "Anzahl_Mitarbeiter_Vollzeit" : {
        "vorhanden" : { },
        "anzahl" : 4
      },
      "Anzahl_Mitarbeiter_Teilzeit" : {
        "vorhanden" : { },
        "anzahl" : 3
      }
    },
    "Patientenkontakt" : {
      "vorhanden" : { },
      "Telefon" : {
        "vorhanden" : { },
        "Rufnummer" : "068112441"
      },
      "Fax" : {
        "vorhanden" : { },
        "Rufnummer" : "068112442"
      },
      "EMail" : {
        "vorhanden" : { },
        "text" : "info@chirurgie-lasar.de"
      },
      "Homepage" : {
        "vorhanden" : { },
        "text" : "www.chirurgie-lasar.de"
      },
      "Mobil" : {
        "nichtVorhanden" : { }
      }
    },
    "Aufenthalt_Behandlung" : {
      "vorhanden" : { },
      "Stationaere_Behandlung" : {
        "nichtVorhanden" : { }
      },
      "Ambulante_Behandlung" : {
        "vorhanden" : { }
      },
      "Teilstationaere_Behandlung" : {
        "vorhanden" : { }
      },
      "Belegbetten_stationaere_Behandlung" : {
        "vorhanden" : { }
      }
    },
    "Profilbild" : {
      "vorhanden" : { },
      "URL" : "lauer.png",
      "Uuid" : "dfsdf",
      "Beschreibung" : "dsfdsf"
    },
    "Oeffnungszeiten" : {
      "vorhanden" : { },
      "Oeffnungszeiten" : [ {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Montag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Montag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Dienstag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Dienstag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Mittwoch"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Mittwoch"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Donnerstag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Donnerstag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Freitag"
      } ]
    },
    "Sprechstunden" : {
      "vorhanden" : { },
      "Sprechstunde" : [ {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Montag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Montag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Dienstag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Dienstag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Mittwoch"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Mittwoch"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Donnerstag"
      }, {
        "Von" : "13:00",
        "Bis" : "17:00",
        "Tag" : "Donnerstag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Freitag"
      } ]
    },
    "Offene_Sprechstunden" : {
      "vorhanden" : { },
      "Offene_Sprechstunde" : [ {
        "Von" : "08:00",
        "Bis" : "10:00",
        "Tag" : "Dienstag"
      }, {
        "Von" : "08:00",
        "Bis" : "12:00",
        "Tag" : "Freitag"
      } ]
    },
    "Abrechnungsart" : {
      "vorhanden" : { },
      "Private_Abrechnung" : {
        "vorhanden" : { }
      },
      "Gesetzliche_Abrechnung" : {
        "vorhanden" : { }
      },
      "Selbstzahler" : {
        "vorhanden" : { }
      }
    },
    "Sprechstundenart" : {
      "vorhanden" : { },
      "Persoenliche_Sprechstunde" : {
        "vorhanden" : { }
      },
      "Video_Sprechstunde" : {
        "vorhanden" : { }
      },
      "Telefon_Sprechstunde" : {
        "vorhanden" : { }
      },
      "Hausbesuch" : {
        "vorhanden" : { }
      }
    },
    "Einrichtung_Vita" : {
      "keineAngabe" : { }
    },
    "Logo_Einrichtung" : {
      "vorhanden" : { },
      "Logo" : {
        "vorhanden" : { },
        "URL" : "lasar.png",
        "Uuid" : "dfsdf",
        "Beschreibung" : "dsfdsf"
      }
    },
    "Auszeichnungen" : {
      "vorhanden" : { },
      "Auszeichnungen" : [ {
        "Bild" : {
          "vorhanden" : { },
          "URL" : "DHG Siegel Qualitätsgesicherte Hernien-Chirurgie.png",
          "Uuid" : "dfsdf",
          "Beschreibung" : "dsfdsf"
        },
        "Name" : "DHG Siegel Qualitätsgesicherte Hernien-Chirurgie.png"
      }, {
        "Bild" : {
          "vorhanden" : { },
          "URL" : "FocusGesundheit2020-2022.png",
          "Uuid" : "dfsdf",
          "Beschreibung" : "dsfdsf"
        },
        "Name" : "DHG Siegel Qualitätsgesicherte Hernien-Chirurgie.png"
      } ]
    },
    "Zertifkate" : {
      "nichtVorhanden" : { }
    },
    "Infomaterial" : {
      "keineAngabe" : { }
    },
    "Kooperationen" : {
      "vorhanden" : { },
      "Kooperation" : [ {
        "Name" : "Evangelisches Stadtkrankenhaus Saarbrücken",
        "Beschreibung" : "Belegbetten",
        "uuid" : "dfdsfs",
        "Homepage" : "https://www.kreuznacherdiakonie.de/krankenhaeuser/ev-stadtkrankenhaus-saarbruecken/"
      } ]
    }
  },
  "Inhaber" : {
    "vorhanden" : { },
    "Titel" : "Dr.",
    "Anrede" : "Herr",
    "Vorname" : "Andreas",
    "Nachname" : "Lauer",
    "Profilbild" : {
      "vorhanden" : { },
      "URL" : "lauer.png",
      "Uuid" : "gfdgdfgdf",
      "Beschreibung" : "Beschreibung"
    },
    "Kontakt" : {
      "vorhanden" : { },
      "Telefon" : {
        "vorhanden" : { },
        "Rufnummer" : "068112441"
      },
      "Fax" : {
        "vorhanden" : { },
        "Rufnummer" : "068112442"
      },
      "EMail" : {
        "vorhanden" : { },
        "text" : "mail@mail.de"
      },
      "Homepage" : {
        "vorhanden" : { },
        "text" : "homepage"
      },
      "Mobil" : {
        "nichtVorhanden" : { }
      }
    }
  },
  "EinrichtungTyp" : {
    "vorhanden" : { },
    "Name" : "Betriebsstätte Arzt",
    "Uuid" : "50cb9d6b-a7ca-4364-b26e-0338d0dcaf56",
    "Kommentar" : ""
  },
  "Kompetenzen" : {
    "vorhanden" : { },
    "Arztqualifikationen" : {
      "vorhanden" : { },
      "Arztgruppe" : {
        "vorhanden" : { },
        "Gruppen" : [ {
          "vorhanden" : { },
          "Bezeichnung" : "Chirurgen"
        } ]
      },
      "Hauptqualifikation" : {
        "vorhanden" : { },
        "Bezeichnung" : "Facharzt für Chirurgie",
        "Uuid" : "dfgdgdf"
      },
      "Weiterbildungen" : {
        "vorhanden" : { },
        "Weiterbildungen" : [ {
          "vorhanden" : { },
          "Bezeichnung" : "Fußchirurgie",
          "Uuid" : "uuid"
        }, {
          "vorhanden" : { },
          "Bezeichnung" : "Schulterchirrurgie",
          "Uuid" : "uuid"
        }, {
          "vorhanden" : { },
          "Bezeichnung" : "Viszeralchirurgie",
          "Uuid" : "uuid"
        }, {
          "vorhanden" : { },
          "Bezeichnung" : "Proktologie",
          "Uuid" : "uuid"
        }, {
          "vorhanden" : { },
          "Bezeichnung" : "Notfallmedizin",
          "Uuid" : "uuid"
        }, {
          "vorhanden" : { },
          "Bezeichnung" : "Unfallchirurgie",
          "Uuid" : "uuid"
        } ]
      },
      "Zusatzqualifikationen" : {
        "keineAngabe" : { }
      },
      "Sonstige_Qualifikationen" : {
        "keineAngabe" : { }
      }
    },
    "Taetigkeits_Schwerpunkte" : {
      "vorhanden" : { },
      "Taetigkeiten" : [ {
        "Bezeichnung" : "Hernienchirurgie (Operationen von Bauchwandbrüchen)",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Leistenschmerz / Sportlerleiste",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Schilddrüsenchirurgie",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Schlüssellochchirurgie (z.B. laparoskopische Gallenblasenentfernung)",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Varizenchirurgie (Krampfaderoperationen)",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Behandlung chronischer Wunden",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Proktologie",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Unfallchirurgie",
        "Uuid" : "abc"
      }, {
        "Bezeichnung" : "Kleinchirurgische Eingriffe",
        "Uuid" : "abc"
      } ]
    },
    "Belegarzt" : {
      "vorhanden" : { }
    },
    "Durchgangsarzt" : {
      "nichtVorhanden" : { }
    },
    "TOP_ICD" : {
      "keineAngabe" : { }
    },
    "TOP_OPS" : {
      "vorhanden" : { }
    },
    "Ambulante_Behandlung" : {
      "vorhanden" : { }
    },
    "Apparative_Ausstattung" : {
      "vorhanden" : { },
      "Apparative_Ausstattung" : [ {
        "Bezeichnung" : "Ruhe - EKG",
        "Uuid" : "sdfdsfsdfsd"
      }, {
        "Bezeichnung" : "Langzeit - EKG",
        "Uuid" : "sdfdsfsdfsd"
      }, {
        "Bezeichnung" : "Belastungs - EKG",
        "Uuid" : "sdfdsfsdfsd"
      } ]
    },
    "Spezialisten" : {
      "vorhanden" : { },
      "Spezialisten" : [ {
        "Identifier" : "2f18e754-0e63-46b6-b6b2-a2e341c8733d",
        "Spezialist" : {
          "vorhanden" : { },
          "Titel" : "Dr.",
          "Anrede" : "Herr",
          "Vorname" : "Andreas",
          "Nachname" : "Lauer",
          "Profilbild" : {
            "vorhanden" : { },
            "URL" : "lauer.png",
            "Uuid" : "gfdgdfgdf",
            "Beschreibung" : "Beschreibung"
          },
          "Kontakt" : {
            "vorhanden" : { },
            "Telefon" : {
              "vorhanden" : { },
              "Rufnummer" : "068112441"
            },
            "Fax" : {
              "vorhanden" : { },
              "Rufnummer" : "068112442"
            },
            "EMail" : {
              "vorhanden" : { },
              "text" : "mail@mail.de"
            },
            "Homepage" : {
              "vorhanden" : { },
              "text" : "homepage"
            },
            "Mobil" : {
              "nichtVorhanden" : { }
            }
          }
        },
        "Arztqualifikationen" : {
          "vorhanden" : { },
          "Arztgruppe" : {
            "vorhanden" : { },
            "Gruppen" : [ {
              "vorhanden" : { },
              "Bezeichnung" : "Chirurgen"
            } ]
          },
          "Hauptqualifikation" : {
            "vorhanden" : { },
            "Bezeichnung" : "Facharzt für Chirurgie",
            "Uuid" : "dfgdgdf"
          },
          "Weiterbildungen" : {
            "vorhanden" : { },
            "Weiterbildungen" : [ {
              "vorhanden" : { },
              "Bezeichnung" : "Fußchirurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Schulterchirrurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Viszeralchirurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Proktologie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Notfallmedizin",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Unfallchirurgie",
              "Uuid" : "uuid"
            } ]
          },
          "Zusatzqualifikationen" : {
            "keineAngabe" : { }
          },
          "Sonstige_Qualifikationen" : {
            "keineAngabe" : { }
          }
        }
      }, {
        "Identifier" : "ba5201f9-d7c9-49ff-bff2-30e5fad8960c",
        "Spezialist" : {
          "vorhanden" : { },
          "Titel" : "Dr.",
          "Anrede" : "Herr",
          "Vorname" : "Nihad",
          "Nachname" : "Sardoschau",
          "Profilbild" : {
            "vorhanden" : { },
            "URL" : "sardoschau.png",
            "Uuid" : "gfdgdfgdf",
            "Beschreibung" : "Beschreibung"
          },
          "Kontakt" : {
            "vorhanden" : { },
            "Telefon" : {
              "vorhanden" : { },
              "Rufnummer" : "068112441"
            },
            "Fax" : {
              "vorhanden" : { },
              "Rufnummer" : "068112442"
            },
            "EMail" : {
              "vorhanden" : { },
              "text" : "mail@mail.de"
            },
            "Homepage" : {
              "vorhanden" : { },
              "text" : "homepage"
            },
            "Mobil" : {
              "nichtVorhanden" : { }
            }
          }
        },
        "Arztqualifikationen" : {
          "vorhanden" : { },
          "Arztgruppe" : {
            "vorhanden" : { },
            "Gruppen" : [ {
              "vorhanden" : { },
              "Bezeichnung" : "Chirurgen"
            } ]
          },
          "Hauptqualifikation" : {
            "vorhanden" : { },
            "Bezeichnung" : "Facharzt für Chirurgie",
            "Uuid" : "dfgdgdf"
          },
          "Weiterbildungen" : {
            "vorhanden" : { },
            "Weiterbildungen" : [ {
              "vorhanden" : { },
              "Bezeichnung" : "Fußchirurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Schulterchirrurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Viszeralchirurgie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Proktologie",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Notfallmedizin",
              "Uuid" : "uuid"
            }, {
              "vorhanden" : { },
              "Bezeichnung" : "Unfallchirurgie",
              "Uuid" : "uuid"
            } ]
          },
          "Zusatzqualifikationen" : {
            "keineAngabe" : { }
          },
          "Sonstige_Qualifikationen" : {
            "keineAngabe" : { }
          }
        }
      } ]
    }
  },
  "Barrierefreiheit" : {
    "vorhanden" : { },
    "Selbsteinschaetzung" : {
      "vorhanden" : { },
      "Rollstuhlfahrer" : {
        "vorhanden" : { },
        "Eignung" : "BEDINGTGEEIGNET"
      },
      "Eingeschraenkte_Mobilitaet" : {
        "vorhanden" : { },
        "Eignung" : "UNGEIGNET"
      },
      "Blinde" : {
        "vorhanden" : { },
        "Eignung" : "GEEIGNET"
      },
      "Gehoerlos" : {
        "vorhanden" : { },
        "Eignung" : "BEDINGTGEEIGNET"
      }
    },
    "Eingangsbereich" : {
      "vorhanden" : { },
      "Ebene" : {
        "vorhanden" : { },
        "Ebenerdig" : {
          "vorhanden" : { }
        },
        "Stockwerk" : {
          "keineAngabe" : { },
          "stockwerke" : 0
        }
      },
      "Tuerschwelle" : {
        "vorhanden" : { },
        "keineTuerSchwelle" : {
          "vorhanden" : { }
        },
        "kleineSchwelle" : {
          "vorhanden" : { }
        },
        "schwelle" : {
          "nichtVorhanden" : { }
        }
      },
      "Treppe" : {
        "nichtVorhanden" : { }
      },
      "Aufzug" : {
        "vorhanden" : { },
        "Rollstuhl" : {
          "nichtVorhanden" : { }
        },
        "Sehvermoegen_eingeschraenkt" : {
          "nichtVorhanden" : { }
        },
        "Gerhoerlose" : {
          "nichtVorhanden" : { },
          "visuelleUnterstuetzung" : {
            "nichtVorhanden" : { }
          }
        }
      }
    },
    "Wartebereich" : {
      "vorhanden" : { },
      "Breite_Tueren" : {
        "vorhanden" : { }
      },
      "Armlehnen" : {
        "vorhanden" : { }
      }
    },
    "WC" : {
      "vorhanden" : { },
      "Notruf" : {
        "nichtVorhanden" : { }
      },
      "Tuerentriegelung" : {
        "vorhanden" : { }
      },
      "Erreichbarkeit" : {
        "vorhanden" : { }
      },
      "Handlauf" : {
        "nichtVorhanden" : { }
      },
      "Rangierplatz" : {
        "vorhanden" : { }
      },
      "Waschbecken" : {
        "nichtVorhanden" : { }
      }
    },
    "Barrierefreiheit_Behandlungszimmer" : {
      "vorhanden" : { },
      "Hoehenverstellbare_Moebel" : {
        "vorhanden" : { }
      },
      "Rangier_Moeglichkeiten" : {
        "vorhanden" : { }
      }
    },
    "Barrierefreiheit_Allgemein" : {
      "vorhanden" : { },
      "Barrierefreie_Beschilderung" : {
        "nichtVorhanden" : { }
      },
      "Piktogramme" : {
        "nichtVorhanden" : { }
      },
      "Glas_markiert" : {
        "nichtVorhanden" : { }
      },
      "Gebaerdesprache" : {
        "nichtVorhanden" : { }
      },
      "Einfache_Informationen" : {
        "vorhanden" : { }
      }
    },
    "Zusatz" : {
      "keineAngabe" : { }
    }
  },
  "Patientensicherheit" : {
    "vorhanden" : { },
    "Verantwortlicher_Patientensicherheit" : {
      "nichtVorhanden" : { }
    },
    "Mitarbeiterschulungen" : {
      "vorhanden" : { },
      "EGK" : {
        "vorhanden" : { }
      },
      "EPA" : {
        "vorhanden" : { }
      },
      "Datenschutz" : {
        "vorhanden" : { }
      },
      "Hygiene" : {
        "vorhanden" : { }
      },
      "Weitere_Schulungen" : {
        "keineAngabe" : { }
      }
    },
    "Regelung_Beschwerdemanagement" : {
      "vorhanden" : { }
    },
    "Regelung_Notfall_RisikoManagement" : {
      "vorhanden" : { },
      "Kontrolle_Notfallaustattung" : {
        "vorhanden" : { }
      },
      "Kriterien_Telefon_Kritisch" : {
        "vorhanden" : { }
      },
      "Notfall_Erkennungstraining" : {
        "vorhanden" : { }
      },
      "Notfalltraining" : {
        "vorhanden" : { }
      },
      "Sonstige" : {
        "nichtVorhanden" : { }
      }
    },
    "Regelung_potentielle_Risiken" : {
      "vorhanden" : { }
    },
    "Sturzrisiko_Analyse" : {
      "vorhanden" : { }
    },
    "Fehlervermeidung_Checklisten" : {
      "vorhanden" : { },
      "Strukturierte_Anamnese" : {
        "vorhanden" : { }
      },
      "Dringlichkeit_Terminvergabe" : {
        "vorhanden" : { }
      },
      "Ueberweisungsvorgang" : {
        "vorhanden" : { }
      },
      "Operative_Eingriffe" : {
        "vorhanden" : { }
      },
      "Sonstige_Taetigkeiten" : {
        "nichtVorhanden" : { }
      }
    },
    "Schnittstellen_Management" : {
      "vorhanden" : { },
      "Kommunikation_vor_nach_Behandler" : {
        "vorhanden" : { }
      },
      "Kontakt_Apotheken" : {
        "vorhanden" : { }
      },
      "Kontakt_Bereiche_ohne_Patientenkontakt" : {
        "vorhanden" : { }
      },
      "Kontakt_Pflege" : {
        "vorhanden" : { }
      },
      "Schnittstelle_stationaere_Behandlung" : {
        "vorhanden" : { }
      },
      "Schnittstelle_Sonstige" : {
        "nichtVorhanden" : { }
      }
    },
    "Fehlermeldesysteme" : {
      "nichtVorhanden" : { }
    },
    "Hygienemassnahmen" : {
      "vorhanden" : { },
      "Verantwortlicher_Hygienemassnahmen" : {
        "nichtVorhanden" : { }
      },
      "KRINKO_Empfehlungen" : {
        "nichtVorhanden" : { }
      },
      "Hygieneplan" : {
        "vorhanden" : { },
        "Verhuetung_nosokomialer_Infektionen" : {
          "vorhanden" : { }
        },
        "Haendehygiene" : {
          "vorhanden" : { }
        },
        "Desinfektionen" : {
          "vorhanden" : { }
        },
        "Medizinprodukte_Vorbereitung" : {
          "vorhanden" : { }
        },
        "Antibiotikaprophylaxe" : {
          "vorhanden" : { }
        },
        "Sonstige_HygieneMassnahmen" : {
          "nichtVorhanden" : { }
        }
      },
      "Gefaehrdungsbeurteilung" : {
        "nichtVorhanden" : { }
      },
      "Massnahmen_Haendewaschplatz" : {
        "vorhanden" : { },
        "Kontaktfreie_Bedienung" : {
          "vorhanden" : { }
        },
        "Handwaeschpraeparat" : {
          "vorhanden" : { }
        },
        "Handdesinfektionsmittel" : {
          "vorhanden" : { }
        },
        "Einmal_Handtuecher" : {
          "vorhanden" : { }
        },
        "Hautpflege_Schutzmittel" : {
          "vorhanden" : { }
        },
        "Abfallbehaelter" : {
          "vorhanden" : { }
        },
        "Sonstige_Massnahmen_Haendewaschplatz" : {
          "nichtVorhanden" : { }
        }
      },
      "Routine_Desinfektion" : {
        "vorhanden" : { },
        "Flaechen_haeufiger_Kontakt" : {
          "vorhanden" : { }
        },
        "Hilfsmittel_Desinfektion" : {
          "vorhanden" : { }
        },
        "Aseptische_Flaechen" : {
          "vorhanden" : { }
        },
        "Fussboden_mit_hohem_Infektionsrisiko" : {
          "vorhanden" : { }
        },
        "Eingriff_Operationsraueme" : {
          "vorhanden" : { }
        },
        "Patientennahe_Flaechen" : {
          "vorhanden" : { }
        },
        "Sichtbar_kontaminierte_Flaechen" : {
          "vorhanden" : { }
        }
      }
    }
  },
  "Patientenservice" : {
    "vorhanden" : { },
    "Aufenthalts_Bereich" : {
      "vorhanden" : { },
      "Service_Wartebereich" : {
        "vorhanden" : { },
        "Angebot_Wasser" : {
          "vorhanden" : { }
        },
        "Angebot_Kaffe" : {
          "nichtVorhanden" : { }
        },
        "Angebot_Zeitschriften" : {
          "vorhanden" : { }
        },
        "Angebot_Kinderspielecke" : {
          "nichtVorhanden" : { }
        },
        "Weitere_Angebote" : {
          "nichtVorhanden" : { }
        }
      },
      "Ausstattung_Aufenthalt_Warte_Bereich" : {
        "vorhanden" : { },
        "Pflanzen" : {
          "vorhanden" : { }
        },
        "Bilder" : {
          "vorhanden" : { }
        },
        "Kostenfreies_Wlan" : {
          "vorhanden" : { }
        },
        "Ausstattung_Aufenthalt_Warte_WeitereAusstattung" : {
          "nichtVorhanden" : { }
        }
      }
    },
    "Sprachangebot" : {
      "vorhanden" : { },
      "Dolmetscherdienst" : {
        "nichtVorhanden" : { }
      },
      "Mehrsprachiges_Personal" : {
        "vorhanden" : { },
        "sprachen" : [ {
          "name" : "Englisch",
          "uuid" : "dfdsfsd"
        }, {
          "name" : "Arabisch",
          "uuid" : "dfdsssfsd"
        } ]
      }
    },
    "Digitale_Anamnese" : {
      "nichtVorhanden" : { }
    },
    "Terminerinnerung" : {
      "vorhanden" : { }
    },
    "Spezialsprechstunden" : {
      "nichtVorhanden" : { }
    },
    "Vorsorge_Check_Up" : {
      "nichtVorhanden" : { }
    },
    "OnlineTerminBuchung" : {
      "vorhanden" : { },
      "Anmerkung" : "https://webtermin.medatixx.de/#/1fb963c5-0e37-4e6e-88ad-7388c5e08b8b/search"
    },
    "VerzoegerungsInformation" : {
      "vorhanden" : { },
      "Verzoegerung_Anruf" : {
        "nichtVorhanden" : { }
      },
      "Verzoegerung_Sms" : {
        "nichtVorhanden" : { }
      },
      "Verzoegerung_Messenger" : {
        "nichtVorhanden" : { }
      },
      "Verzoegerung_Cityrufgeraet" : {
        "nichtVorhanden" : { }
      },
      "Verzoegerung_Drittanbieter" : {
        "nichtVorhanden" : { }
      }
    },
    "Abschiedsraum" : {
      "nichtVorhanden" : { }
    },
    "E_Rezept" : {
      "vorhanden" : { }
    },
    "Ausstellung_Digitale_AU" : {
      "nichtVorhanden" : { }
    }
  },
  "Weitere_Standorte" : {
    "vorhanden" : { }
  },
  "Bilder" : {
    "vorhanden" : { },
    "Bilder" : [ {
      "vorhanden" : { },
      "URL" : "https://media.librecontent.org/files/0/d/0dd8d1dc-2b04-4f84-87c8-af2262fac7db.jpg",
      "Uuid" : "0dd8d1dc-2b04-4f84-87c8-af2262fac7db",
      "Beschreibung" : "Sehr geehrte Patientinnen und Patienten, sehr geehrte Kolleginnen und Kollegen, im Januar 2016 haben wir uns beruflich verändert und unsere chirurgische Tätigkeit auf den ambulanten Versorgungsbereich ausgeweitet. Die von uns gegründete Chirurgische Gemeinschaftspraxis LASAR liegt in unmittelbarer Nähe zum Evangelischen Stadtkrankenhaus Saarbrücken und ist als zertifiziertes Hernienzentrum zugelassen. Es werden gesetzlich und privat versicherte Patienten versorgt. Die Versorgung unserer stationären Patienten erfolgt weiterhin durch uns persönlich und in der gewohnten Qualität. Wir sind Belegärzte im Ev. Stadtkrankenhaus und führen dort gemeinsam die Chirurgische Belegabteilung."
    }, {
      "vorhanden" : { },
      "URL" : "https://media.librecontent.org/files/c/1/c13a437a-0de2-45cf-a8e8-caa3aa16b0eb.jpg",
      "Uuid" : "c13a437a-0de2-45cf-a8e8-caa3aa16b0eb",
      "Beschreibung" : "Sehr geehrte Patientinnen und Patienten, sehr geehrte Kolleginnen und Kollegen, im Januar 2016 haben wir uns beruflich verändert und unsere chirurgische Tätigkeit auf den ambulanten Versorgungsbereich ausgeweitet. Die von uns gegründete Chirurgische Gemeinschaftspraxis LASAR liegt in unmittelbarer Nähe zum Evangelischen Stadtkrankenhaus Saarbrücken und ist als zertifiziertes Hernienzentrum zugelassen. Es werden gesetzlich und privat versicherte Patienten versorgt. Die Versorgung unserer stationären Patienten erfolgt weiterhin durch uns persönlich und in der gewohnten Qualität. Wir sind Belegärzte im Ev. Stadtkrankenhaus und führen dort gemeinsam die Chirurgische Belegabteilung."
    }, {
      "vorhanden" : { },
      "URL" : "https://media.librecontent.org/files/c/1/c1c60bb7-5111-462b-8fdb-25e3a443ce7d.jpg",
      "Uuid" : "c1c60bb7-5111-462b-8fdb-25e3a443ce7d",
      "Beschreibung" : "Sehr geehrte Patientinnen und Patienten, sehr geehrte Kolleginnen und Kollegen, im Januar 2016 haben wir uns beruflich verändert und unsere chirurgische Tätigkeit auf den ambulanten Versorgungsbereich ausgeweitet. Die von uns gegründete Chirurgische Gemeinschaftspraxis LASAR liegt in unmittelbarer Nähe zum Evangelischen Stadtkrankenhaus Saarbrücken und ist als zertifiziertes Hernienzentrum zugelassen. Es werden gesetzlich und privat versicherte Patienten versorgt. Die Versorgung unserer stationären Patienten erfolgt weiterhin durch uns persönlich und in der gewohnten Qualität. Wir sind Belegärzte im Ev. Stadtkrankenhaus und führen dort gemeinsam die Chirurgische Belegabteilung."
    }, {
      "vorhanden" : { },
      "URL" : "https://media.librecontent.org/files/e/8/e8b19dee-556d-4664-b9a7-75540769e725.jpg",
      "Uuid" : "e8b19dee-556d-4664-b9a7-75540769e725",
      "Beschreibung" : "Sehr geehrte Patientinnen und Patienten, sehr geehrte Kolleginnen und Kollegen, im Januar 2016 haben wir uns beruflich verändert und unsere chirurgische Tätigkeit auf den ambulanten Versorgungsbereich ausgeweitet. Die von uns gegründete Chirurgische Gemeinschaftspraxis LASAR liegt in unmittelbarer Nähe zum Evangelischen Stadtkrankenhaus Saarbrücken und ist als zertifiziertes Hernienzentrum zugelassen. Es werden gesetzlich und privat versicherte Patienten versorgt. Die Versorgung unserer stationären Patienten erfolgt weiterhin durch uns persönlich und in der gewohnten Qualität. Wir sind Belegärzte im Ev. Stadtkrankenhaus und führen dort gemeinsam die Chirurgische Belegabteilung."
    } ]
  }
}