import { Button, Card, Col, Container, Form, FormGroup, FormLabel, Image, Row } from "react-bootstrap";
import * as Constants from '../../Constants';
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logos/mediWiki.svg'
import '../../login.scss'

const Login = () => {
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.currentTarget.value)
  }

  const submitForm = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    setError('')
    localStorage.removeItem('user-token')
    const tokenJson: { token: string } = await fetch(Constants.SERVICEURL + 'auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: password
    })
      .then(response => response.json());

    if (tokenJson?.token?.length > 0) {
      localStorage.setItem('user-token', tokenJson.token)
      return navigate('/');
    } else {
      setError('falsches Passwort')
    }
  }

  return (
    <div className="authContainer">
      <Card>
        <div className="authHeader">
          <h1 className="authTitle">
            <Image src={logo} alt="Logo" width={200} />
          </h1>
        </div>
        <div className="authContent">
          <Row>
            <Col>
              <Form onSubmit={submitForm}>
                <FormGroup>
                  {/* <FormLabel>Passwort</FormLabel> */}
                  <Form.Control
                    className="underlinedInput"
                    value={password}
                    onChange={handleChange}
                    type="password"
                    placeholder="Passwort"
                  />
                </FormGroup>
                {error.length > 0 && (
                  <div style={{ color: '#ff0000' }}>{error}</div>
                )}
                <br />
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
    </div >
  )
}

export default Login
