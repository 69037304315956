import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './assets/fonts/index.css';
import Article, { loader as articleLoader } from './components/Content/Article/Article';
import Error from './components/Content/Error/Error';
import Home, { loader as homeLoader } from './components/Content/Home/Home';
import Practice, { loader as practiceLoader } from './components/Content/Practice/Practice';
import Search from './components/Content/Search/Search';
import Specialist, { loader as specialistLoader } from './components/Content/Specialist/Specialist';
import Auth from './components/Login/Auth';
import Login from './components/Login/Login';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './theme.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import EditModal from './components/Content/components/EditModal';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <Login />
  },
  {
    path: '/',
    element: <Auth />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: '/search',
        element: <Search />,
      },
      {
        path: 'article/:articleId',
        element: <Article />,
        loader: articleLoader,
      },
      {
        path: 'article/:articleId/:parentId',
        element: <Article />,
        loader: articleLoader,
      },
      {
        path: 'practice/:practiceId',
        element: <Practice />,
        loader: practiceLoader,
      },
      {
        path: 'specialist/:specialistId',
        element: <Specialist />,
        loader: specialistLoader,
      },
      {
        path: 'test',
        element: <EditModal />,
      }]
  }
])
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
