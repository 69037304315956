export const engstler = {
  "Spezialist": {
    "vorhanden": {},
    "Titel": "Dr. med. dent.",
    "Anrede": "Frau",
    "Vorname": "Barbara",
    "Nachname": "Engstler",
    "Profilbild": {
      "keineAngabe": {}
    },
    "Kontakt": {
      "vorhanden": {},
      "Telefon": {
        "vorhanden": {},
        "Rufnummer": "06 81 / 5 14 02"
      },
      "Fax": {
        "nichtVorhanden": {}
      },
      "EMail": {
        "nichtVorhanden": {}
      },
      "Homepage": {
        "nichtVorhanden": {}
      },
      "Mobil": {
        "nichtVorhanden": {}
      }
    }
  },
  "Einleitung_Spezialist_Text": {
    "keineAngabe": {}
  },
  "Arztqualifikationen": {
    "vorhanden": {},
    "Hauptqualifikation": {
      "vorhanden": {},
      "Bezeichnung": "Zahnärztin",
      "Uuid": "dsfdsfds"
    },
    "Weiterbildungen": {
      "keineAngabe": {}
    },
    "Zusatzqualifikationen": {
      "keineAngabe": {}
    },
    "Sonstige_Qualifikationen": {
      "keineAngabe": {}
    }
  },
  "Taetigkeits_Schwerpunkte": {
    "keineAngabe": {}
  },
  "Spezialist_Vita": {
    "keineAngabe": {}
  },
  "Auszeichnungen": {
    "keineAngabe": {}
  },
  "Weitere_Standorte": {
    "keineAngabe": {}
  },
  "Medienpraesenz": {
    "keineAngabe": {}
  },
  "Bilder": {
    "keineAngabe": {}
  },
  "Mitgliedschaften": {
    "keineAngabe": {}
  }
}