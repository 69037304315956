import { Col, Container, Image, Row } from 'react-bootstrap'
import lc_logo from '../../assets/logos/libre_content.svg'
import mediWiki_white from '../../assets/logos/mediWiki_white.svg'

const Footer = () => {
  return (
    <>
      <footer className="footerContainer">
        <Container>
          <hr />
          <Row>
            <Col>
              <Row style={{ alignItems: 'flex-end' }}>
                <Col>
                  <Image src={mediWiki_white} alt="mediWiki-Logo" />
                </Col>
                <Col>
                  mediWiki ist ein Projekt der
                </Col>
                <Col>
                  <Image src={lc_logo} alt="LC-Logo" width={210} />
                </Col>
              </Row>
            </Col>

            {/* <Col>
              <Row>
                <Col>
                  In Kooperation mit dem Institut für Qualität und Wirtschaftlichkeit im Gesundheitswesen sowie www.gesundheitsinformation.de
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image src={iqwig_logo} alt="LC-Logo" width={110} />
                </Col>
                <Col>
                  <Image src={gi_logo} alt="LC-Logo" width={35} />
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
