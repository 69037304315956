import { Col, Row } from 'react-bootstrap';
import { elements } from "../../../../types/Transparenzbericht";

interface OffeneSprechstundenCardProps {
  data?: elements.strukturdaten.OffeneSprechstunde
}

const OffeneSprechstundenCard = (props: OffeneSprechstundenCardProps) => {
  let sprechstunden = {
    Montag: [],
    Dienstag: [],
    Mittwoch: [],
    Donnerstag: [],
    Freitag: [],
    Samstag: [],
    Sonntag: []
  };

  if (props.data?.Offene_Sprechstunde) {
    sprechstunden = props.data.Offene_Sprechstunde.reduce((accu: any, curr: any) => {
      const time = <>{curr.Von} &ndash; {curr.Bis}</>
      return {
        ...accu,
        [curr.Tag]: [...(accu[curr.Tag] || []), time]
      }
    }, sprechstunden)
  }

  return (
    <>
      {sprechstunden.Montag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Montag
          </Col>
          {sprechstunden.Montag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Dienstag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Dienstag
          </Col>
          {sprechstunden.Dienstag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Mittwoch.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Mittwoch
          </Col>
          {sprechstunden.Mittwoch.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Donnerstag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Donnerstag
          </Col>
          {sprechstunden.Donnerstag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Freitag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Freitag
          </Col>
          {sprechstunden.Freitag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Samstag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Samstag
          </Col>
          {sprechstunden.Samstag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
      {sprechstunden.Sonntag.length > 0 && (
        <Row className="cardContentLine">
          <Col md={2}>
            Sonntag
          </Col>
          {sprechstunden.Sonntag.map((time, index) => (
            <Col key={index} md={2}>{time}</Col>
          )
          )}
        </Row>
      )}
    </>
  )
}

export default OffeneSprechstundenCard
