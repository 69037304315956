import { elements } from "../../../../types/Transparenzbericht";

interface VitaCardProps {
  vita: elements.Vita | undefined
}

const VitaCard = (props: VitaCardProps) => {
  return (
    <>
      {props.vita?.Vita?.map((vita, index) =>
        <span key={index} className="listLine">
          <span className="listLineDot">&bull;</span>
          &ensp;{vita.Von}{vita.Bis ? ` - ${vita.Bis}` : ''} {vita.Bezeichnung}
        </span>
      )}
    </>
  )
}

export default VitaCard
