import { Field, Form, Formik, FormikHelpers } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import Icon from "../components/Icon";
import * as Constants from '../../../Constants';

export interface formValues {
  entity_type: string;
  searchTerm: string;
  state: string;
}

interface SearchFormProps {
  handleSubmit: (values: formValues, { setSubmitting }: FormikHelpers<formValues>) => void
}

const SearchForm = (props: SearchFormProps) => {
  const laenderSelect = ["Baden-Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"]

  return (
    <div className="searchForm">
      <Container>
        <Formik
          initialValues={{
            entity_type: Constants.entityType.SPECIALIST,
            searchTerm: "",
            state: laenderSelect[11],
          }}
          onSubmit={props.handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Row style={{ marginBottom: '1.5rem' }}>
                <Col>
                  <label>
                    <Field
                      type="radio"
                      value={Constants.entityType.ORGANIZATION}
                      label="Einrichtung"
                      name="entity_type"
                    /> Einrichtung
                  </label>
                  <label style={{ marginLeft: '3rem' }}>
                    <Field
                      type="radio"
                      value={Constants.entityType.SPECIALIST}
                      label="Spezialist"
                      name="entity_type"
                    /> Spezialist
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Field
                    id="searchTerm"
                    name="searchTerm"
                    placeholder={values.entity_type === Constants.entityType.ORGANIZATION ? 'Name der Einrichtung' : 'Name, Vorname des Spezialisten'}
                    type="input"
                  />
                </Col>
                <Col md={3}>
                  <Field
                    id="state"
                    name="state"
                    component="select"
                  >
                    {laenderSelect.map(land => (
                      <option key={land} value={land}>{land}</option>
                    )
                    )}
                  </Field>
                </Col>
                <Col>
                  <Button variant="primary" type="submit">
                    <span className="valign-wrapper">
                      <Icon icon="search" />
                      Suche
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

export default SearchForm
