import { Container, Image, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../assets/logos/mediWiki.svg'
import Icon from '../Content/components/Icon'



const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="headerContainer">
        <Container>
          <Navbar.Brand href="/">
            <Image src={logo} alt="Logo" width={150} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="header-navbar" />
          <Navbar.Collapse id="header-navbar">
            <Nav className="justify-content-end flex-grow-1">
              <Link to="/search" className="valign-wrapper" style={{ textDecoration: 'none' }}>
                <Icon icon="search" />
                Suche
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header