import { Card, Col, Image, Row } from "react-bootstrap"
import { elements } from "../../../types/Transparenzbericht"
import { Link } from "react-router-dom"

interface SpezialistenProps {
  data: elements.kompetenzen.Spezialisten_In_Einrichtung | undefined
}

const Spezialisten = (props: SpezialistenProps) => {
  return (
    <>
      {props.data?.Spezialisten?.map((spezialist, index) =>
        
          <Row key={index} className="specialistCard">
            <Col md={4}>
              <Image src={spezialist.Spezialist?.Profilbild?.URL} style={{ width: '100%' }} />
            </Col>
            <Col md={8}>
              <Card className="" id="sp1">
                <Card.Header><h5>{spezialist.Spezialist?.Titel + ' ' + spezialist.Spezialist?.Vorname + ' ' + spezialist.Spezialist?.Nachname}</h5></Card.Header>
                <Card.Body>
                  <ul>
                    <li>{spezialist.Arztqualifikationen?.Hauptqualifikation?.Bezeichnung}</li>
                    {spezialist.Arztqualifikationen?.Weiterbildungen?.Weiterbildungen?.map((weiterbildung, index) =>
                      <li key={index}>{weiterbildung.Bezeichnung}</li>
                    )}
                  </ul>
                  <Link  to={`/specialist/${spezialist.Identifier}`}>Zum Profil</Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        
      )
      }
    </>
  )
}

export default Spezialisten
