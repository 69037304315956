import { elements } from "../../../types/Transparenzbericht";
import CardContent from "../components/Card/CardContent";
import KontaktCard from "../components/Card/KontaktCard";
import ContentCard from "../components/ContentCard";
import ImageSlideshow from "../components/ImageSlideshow";
import OffeneSprechstundenCard from "../components/Practice/OffeneSprechstundenCard";
import Parkmoeglichkeiten from "../components/Practice/Parkmoeglichkeiten";
import SprechstundenCard from "../components/Practice/SprechstundenCard";
import SectionHeader from "../components/SectionHeader";

interface PatientenserviceProps {
  data: elements.Transparenz_Xml_Einrichtung | undefined,
}

const Patientenservice = (props: PatientenserviceProps) => {
  return (
    <>
      <SectionHeader title="A &ndash; Patientenservice">
        <>
          <ImageSlideshow data={props.data?.Bilder} />
          <ContentCard
            header="Einleitung"
            data={props.data?.Einleitung}
          >
            {props.data?.Einleitung?.vorhanden && (
              <>{props.data?.Einleitung.Einleitungs_Einrichtung_Text?.text}</>
            )}
          </ContentCard>
          <ContentCard
            header="Kontakt"
            id="contactCard"
            data={props.data?.Strukturdaten?.Adresse_Einrichtung}
          >
            <KontaktCard adresse={props.data?.Strukturdaten?.Adresse_Einrichtung?.adresse} kontakt={props.data?.Strukturdaten?.Patientenkontakt} />
          </ContentCard>
          <ContentCard header="Weitere Standorte">
          </ContentCard>
          <ContentCard
            header="Sprechstundenarten"
            data={props.data?.Strukturdaten?.Sprechstundenart}
          >
            <CardContent type="JaNein" title="Persönlich" data={props.data?.Strukturdaten?.Sprechstundenart?.Persoenliche_Sprechstunde} />
            <CardContent type="JaNein" title="Videosprechstunde" data={props.data?.Strukturdaten?.Sprechstundenart?.Video_Sprechstunde} />
            <CardContent type="JaNein" title="Telefonsprechstunde" data={props.data?.Strukturdaten?.Sprechstundenart?.Telefon_Sprechstunde} />
            <CardContent type="JaNein" title="Hausbesuche" data={props.data?.Strukturdaten?.Sprechstundenart?.Hausbesuch} />
          </ContentCard>
          <ContentCard
            header="Sprechstundenzeiten"
            data={props.data?.Strukturdaten?.Sprechstunden}
          >
            {props.data?.Strukturdaten?.Sprechstunden?.vorhanden && (
              <SprechstundenCard data={props.data?.Strukturdaten?.Sprechstunden} />
            )}
          </ContentCard>
          <ContentCard
            header="Offene Sprechstunde"
            data={props.data?.Strukturdaten?.Offene_Sprechstunden}
          >
            {props.data?.Strukturdaten?.Offene_Sprechstunden?.vorhanden && (
              <OffeneSprechstundenCard data={props.data?.Strukturdaten?.Offene_Sprechstunden} />
            )}
          </ContentCard>
          <ContentCard
            header="Abrechnungsart"
            data={props.data?.Strukturdaten?.Abrechnungsart}
          >
            <CardContent type="JaNein" title="Privat" data={props.data?.Strukturdaten?.Abrechnungsart?.Private_Abrechnung} />
            <CardContent type="JaNein" title="Gesetzlich" data={props.data?.Strukturdaten?.Abrechnungsart?.Gesetzliche_Abrechnung} />
            <CardContent type="JaNein" title="Selbstzahler" data={props.data?.Strukturdaten?.Abrechnungsart?.Selbstzahler} />
          </ContentCard>
          <ContentCard
            header="Terminerinnerung"
          >
            <CardContent type="JaNein" title="Terminerinnerung" data={props.data?.Patientenservice?.Terminerinnerung} />
          </ContentCard>
          <ContentCard
            header="Aufenthalt und Behandlung"
            data={props.data?.Strukturdaten?.Aufenthalt_Behandlung}
          >
            <CardContent type="JaNein" title="stationäre Behandlung" data={props.data?.Strukturdaten?.Aufenthalt_Behandlung?.Stationaere_Behandlung} />
            <CardContent type="JaNein" title="ambulante Behandlung" data={props.data?.Strukturdaten?.Aufenthalt_Behandlung?.Ambulante_Behandlung} />
            <CardContent type="JaNein" title="teilstationäre Behandlung" data={props.data?.Strukturdaten?.Aufenthalt_Behandlung?.Teilstationaere_Behandlung} />
            <CardContent type="JaNein" title="stationäre Behandlung mit Belegbetten" data={props.data?.Strukturdaten?.Aufenthalt_Behandlung?.Belegbetten_stationaere_Behandlung} />
          </ContentCard>
          <ContentCard
            header="Service im Wartebereich"
            hint="Welche der folgenden Services bietet die Einrichtung?"
          >
            <CardContent type="JaNein" title="Kaffee" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Service_Wartebereich?.Angebot_Kaffe} />
            <CardContent type="JaNein" title="Wasser" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Service_Wartebereich?.Angebot_Wasser} />
            <CardContent type="JaNein" title="Kinderspielecke" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Service_Wartebereich?.Angebot_Kinderspielecke} />
            <CardContent type="JaNein" title="Zeitschriften" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Service_Wartebereich?.Angebot_Zeitschriften} />
          </ContentCard>
          <ContentCard
            header="Ausstattung im Wartebereich"
            hint="Welche der folgenden Services bietet die Einrichtung?"
            data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Ausstattung_Aufenthalt_Warte_Bereich}
          >
            <CardContent type="JaNein" title="Pflanzen" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Ausstattung_Aufenthalt_Warte_Bereich?.Pflanzen} />
            <CardContent type="JaNein" title="Bilder" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Ausstattung_Aufenthalt_Warte_Bereich?.Bilder} />
            <CardContent type="JaNein" title="kostenfreies WLAN" data={props.data?.Patientenservice?.Aufenthalts_Bereich?.Ausstattung_Aufenthalt_Warte_Bereich?.Kostenfreies_Wlan} />
            <CardContent type="JaNein" title="Größe Wartebereich in m²" text={props.data?.Patientenservice?.Aufenthalts_Bereich?.Ausstattung_Aufenthalt_Warte_Bereich?.Wartebereich_Groesse?.groesse} />
          </ContentCard>
          <ContentCard
            header="Mehrsprachiges Personal"
            hint="In dieser Einrichtung versteht man Sie in folgenden Sprachen:"
            data={props.data?.Patientenservice?.Sprachangebot?.Mehrsprachiges_Personal}
          >
            {props.data?.Patientenservice?.Sprachangebot?.vorhanden &&
              props.data?.Patientenservice?.Sprachangebot?.Mehrsprachiges_Personal?.sprachen?.map((sprache, index) =>
                <li key={index}>{sprache.name}</li>
              )}
          </ContentCard>
          <ContentCard
            header="Parkmöglichkeiten"
          >
            <Parkmoeglichkeiten />
          </ContentCard>
          <ContentCard
            header="Ergänzende Angebote"
            hint="Ergänzende Angebote für Patienten"
          >
            <CardContent type="JaNein" title="Digitale Anamnese" data={props.data?.Patientenservice?.Digitale_Anamnese} />
            <CardContent type="JaNein" title="Spezialsprechstunden" data={props.data?.Patientenservice?.Spezialsprechstunden} />
            <CardContent type="JaNein" title="Vorsorge Check-Ups" data={props.data?.Patientenservice?.Vorsorge_Check_Up} />
            <CardContent type="JaNein" title="Termine online buchen" data={props.data?.Patientenservice?.OnlineTerminBuchung} />
            <CardContent type="JaNein" title="Termin-Verzögerungs-Info Dienste" data={props.data?.Patientenservice?.VerzoegerungsInformation} />
          </ContentCard>

        </>
      </SectionHeader>
    </>
  )
}

export default Patientenservice
