import { elements } from "../../../types/Transparenzbericht";
import CardContent from "../components/Card/CardContent";
import ContentCard from "../components/ContentCard";

interface LeistungenProps {
  data: elements.TransparenzXmlSpezialist | undefined;
}

const Leistungen = (props: LeistungenProps) => {
  return (
    <>
      <ContentCard
        header="Kompetenzen"
        data={props.data}
        collapsed
      >
        {props.data?.Taetigkeits_Schwerpunkte?.Taetigkeiten?.map((taetigkeit, index) =>
          <CardContent key={index} type="List" text={taetigkeit.Bezeichnung} />
        )}
      </ContentCard>
      <ContentCard
        header="Kooperationen und Partnerschaften"
        data={props.data?.Kooperationen}
        collapsed
      >
        {props.data?.Kooperationen?.Kooperation?.map((kooperation, index) =>
          <CardContent key={index} type="List" text={kooperation.Name} />
        )}
      </ContentCard>
      <ContentCard
        header="Auszeichnungen / Zertifikate"
        data={props.data?.Auszeichnungen}
        collapsed
      >
        {props.data?.Auszeichnungen?.auszeichnungen?.map((auszeichnung, index) =>
          <CardContent key={index} type="List" text={auszeichnung.Kommentar} />
        )}
      </ContentCard>
      <ContentCard
        header="Mitgliedschaften"
        data={props.data?.Mitgliedschaften}
        collapsed
      >
        {props.data?.Mitgliedschaften?.mitglied?.map((mitgliedschaft, index) =>
          <CardContent key={index} type="List" text={mitgliedschaft.Name} />
        )}
      </ContentCard>
      <ContentCard
        header="Veröffentlichungen"
        data={props.data?.Veroeffentlichungen}
        collapsed
      >
        {props.data?.Veroeffentlichungen?.veroeffentlichungen?.map((veroeffentlichung, index) =>
          <CardContent key={index} type="List" text={veroeffentlichung.Name} />
        )}
      </ContentCard>
    </>
  )
}

export default Leistungen
