import { elements } from "../../../types/Transparenzbericht"
import VitaCard from "../components/Card/VitaCard"
import ContentCard from "../components/ContentCard"

interface VitaProps {
  data: elements.TransparenzXmlSpezialist
}

const Vita = (props: VitaProps) => {
  return (
    <>
      <>
        <ContentCard
          header="Vita"
          data={props.data?.Spezialist_Vita}
          collapsed
        >
          {props.data?.Einleitung_Spezialist_Text?.text}
          <VitaCard vita={props.data?.Spezialist_Vita} />
        </ContentCard>
      </>
    </>
  )
}

export default Vita
