import { Col, Row } from "react-bootstrap"

interface ParkmoeglichkeitenProps {
  data?: {
    vorhanden?: {},
    nichtVorhanden?: {},
    keineAngaben?: {},
    [key: string]: any
  }
}

const Parkmoeglichkeiten = () => {
  return (
    <>
      <Row className="cardContentLine">
        <Col md={2}>
          2
        </Col>
        <Col md={3}>
          Kostenfrei
        </Col>
        <Col md={1}>
          &lt;25m
        </Col>
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          10
        </Col>
        <Col md={3}>
          Kostenpflichtig
        </Col>
        <Col md={1}>
          &lt;75m
        </Col>
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          30
        </Col>
        <Col md={3}>
          Kostenfrei
        </Col>
        <Col md={1}>
          &lt;100m
        </Col>
      </Row>
    </>
  )
}

export default Parkmoeglichkeiten
