export const lauer = {
  "Identifier": "393fd548-9663-4f05-822f-2aac99e2ec1c",
  "Spezialist": {
    "vorhanden": {},
    "Titel": "Dr.",
    "Anrede": "Herr",
    "Vorname": "Andreas",
    "Nachname": "Lauer",
    "Profilbild": {
      "URL": "url",
      "Beschreibung": "Beschreibung"
    },
    "Kontakt": {
      "vorhanden": {},
      "Telefon": {
        "vorhanden": {},
        "Rufnummer": "068112441"
      },
      "Fax": {
        "vorhanden": {},
        "Rufnummer": "068112442"
      },
      "EMail": {
        "vorhanden": {},
        "text": "mail@mail.de"
      },
      "Homepage": {
        "vorhanden": {},
        "text": "homepage"
      },
      "Mobil": {
        "nichtVorhanden": {}
      }
    }
  },
  "Berufsgruppe": {
    "vorhanden": {},
    "Name": "Chirurgen",
    "Uuid": "dsfdsfsd",
    "Kommentar": ""
  },
  "Einleitung_Spezialist_Text": {
    "vorhanden": {},
    "text": "Über Dr. Andreas Lauer\n Dr. med. Andreas Lauer hat zum Jahresende 2015 seine Oberarzttätigkeit in der Chirurgischen Abteilung des Ev. Stadtkrankenhauses beendet. Neben seiner ambulanten Praxistätigkeit behandelt er weiterhin auch stationäre Patienten im Krankenhaus. Die Versorgung seiner Patienten erfolgt durch ihn persönlich und in gewohnter Qualität.\nAndreas Lauer ist Facharzt für Chirurgie und Unfallchirurgie. Er war mehr als 22 Jahre im Ev. Stadtkrankenhaus tätig, davon in der Zeit von 2002-2015 als Oberarzt und zuletzt als der ständige ärztliche Stellvertreter des Chefarztes N. Sardoschau. Er war zugleich als Vertreter von N. Sardoschau als hauptverantwortlicher Operateur des Hernienzentrums benannt. Andreas Lauer hat im Laufe seiner Krankenhaustätigkeit zahlreiche zusätzliche Kompetenzen erworben. Er ist im Besonderen seit Jahren mit der Behandlung von Patienten mit chronischen Problemwunden vertraut und war mehrere Jahre ärztlicher Sektionsleiter einer interdisziplinär geführten septischen Station des Krankenhauses. Andreas Lauer deckt aufgrund seiner ärztlichen Erfahrung auch das Gebiet der viszeralchirurgischen, konventionellen und laparoskopischen Operationen weitestgehend ab."
  },
  "Arztqualifikationen": {
    "vorhanden": {},
    "Arztgruppe": {
      "vorhanden": {},
      "Gruppen": [{
        "vorhanden": {},
        "Bezeichnung": "Chirurgen"
      }]
    },
    "Hauptqualifikation": {
      "vorhanden": {},
      "Bezeichnung": "Facharzt für Chirurgie und Unfallchirurgie",
      "Uuid": "uuid"
    },
    "Weiterbildungen": {
      "nichtVorhanden": {}
    },
    "Zusatzqualifikationen": {
      "nichtVorhanden": {}
    },
    "Sonstige_Qualifikationen": {
      "nichtVorhanden": {}
    }
  },
  "Taetigkeits_Schwerpunkte": {
    "vorhanden": {},
    "Taetigkeiten": [{
      "Bezeichnung": "Hernienchirurgie (Operationen von Bauchwandbrüchen)",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Leistenschmerz / Sportlerleiste",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Schilddrüsenchirurgie",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Schlüssellochchirurgie (z.B. laparoskopische Gallenblasenentfernung)",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Varizenchirurgie (Krampfaderoperationen)",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Behandlung chronischer Wunden",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Proktologie",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Unfallchirurgie",
      "Uuid": "abc"
    }, {
      "Bezeichnung": "Kleinchirurgische Eingriffe",
      "Uuid": "abc"
    }]
  },
  "Spezialist_Vita": {
    "vorhanden": {},
    "Vita": [{
      "Von": "1998",
      "Bezeichnung": "Promotion"
    }, {
      "Von": "1999",
      "Bezeichnung": "Facharzt für Orthopädie und Unfallchirurgie"
    }, {
      "Von": "1997",
      "Bis": "2002",
      "Bezeichnung": "Assistenzarzt am Evangelischen Krankenhaus Saarbrücken"
    }, {
      "Von": "2002",
      "Bis": "2015",
      "Bezeichnung": "Oberarzt am Evangelischen Krankenhaus Saarbrücken"
    }, {
      "Von": "2015",
      "Bezeichnung": "Stellvertreter des Chefarztes am Evangelischen Krankenhaus Saarbrücken"
    }, {
      "Von": "2015",
      "Bis": "Heute",
      "Bezeichnung": "Leiter der Chirurgischen Gemeinschaftspraxis LASAR"
    }]
  },
  "Auszeichnungen": {
    "vorhanden": {}
  },
  "Weitere_Standorte": {
    "vorhanden": {},
    "Standorte": [{
      "Adresse": {
        "vorhanden": {},
        "Strasse": "Schmollerstraße",
        "Hausnummer": "28",
        "Postleitzahl": "66111",
        "Ort": "Saarbrücken",
        "Bundesland": "Saarland"
      },
      "Anmerkung": "Chirurgische Gemeischaftspraxis Lauer & Sardoschau",
      "Uuid": "fdsfdsfsdsfsdfdfdsfdsfdsfds"
    }, {
      "Adresse": {
        "vorhanden": {},
        "Strasse": "Großherzog-Friedrich-Straße",
        "Hausnummer": "44",
        "Postleitzahl": "66111",
        "Ort": "Saarbrücken",
        "Bundesland": "Saarland"
      },
      "Anmerkung": "Evangelisches Krankenhaus Saarbrücken",
      "Uuid": "fdsfdsfsdsfsdfdfdsfdsfdsfds"
    }]
  },
  "Medienpraesenz": {
    "vorhanden": {},
    "Medienpraesenz": [{
      "vorhanden": {},
      "URL": "https://www.jameda.de/gesundheitseinrichtungen/lasar-chirugisches-zentrum-dr-med-andreas-lauer-und-nihad-sardoschau",
      "Kommentar": "Jameda-Profil",
      "Name": "Jameda"
    }, {
      "vorhanden": {},
      "URL": "https://www.jameda.de/gesundheitseinrichtungen/lasar-chirugisches-zentrum-dr-med-andreas-lauer-und-nihad-sardoschau",
      "Kommentar": "Arztsuche-Profil",
      "Name": "Arztsuche"
    }]
  },
  "Kooperationen": {
    "nichtVorhanden": {}
  },
  "Bilder": {
    "vorhanden": {},
    "Bilder": [{
      "vorhanden": {},
      "URL": "lauer.png",
      "Uuid": "dsfdsfsd",
      "Beschreibung": ""
    }]
  },
  "Mitgliedschaften": {
    "nichtVorhanden": {}
  },
  "Veroeffentlichungen": {
    "nichtVorhanden": {}
  }
}