import { useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import Icon from "./Icon";

interface ContentCardProps {
  header?: string;
  id?: string;
  hint?: string;
  children?: React.ReactNode
  data?: {
    vorhanden?: {},
    nichtVorhanden?: {},
    keineAngaben?: {},
    [key: string]: any
  };
  keineAngabeText?: string;
  nichtVorhandenText?: string;
  collapsed?: boolean;
  notCollapsable?: boolean;
}

const ContentCard = (props: ContentCardProps) => {
  const [isContentOpen, setIsContentOpen] = useState<boolean>(false)
  useEffect(() => setIsContentOpen(props.collapsed !== undefined ? !props.collapsed : true), [props.collapsed])

  const openContent = () => {
    setIsContentOpen(!isContentOpen)
  }

  let content = props.children;

  if (props.data?.keineAngabe) {
    content = <div className="valign-wrapper">
      <Icon icon="circle" style={{ color: '#E35D6A' }} />
      {props.keineAngabeText ? props.keineAngabeText : 'Nicht angegeben'}
    </div>
  }

  if (props.data?.nichtVorhanden) {
    content = <div className="valign-wrapper">
      <Icon icon="do_not_disturb_on" />
      {props.nichtVorhandenText ? props.nichtVorhandenText : 'Nicht vorhanden'}
    </div>
  }

  return (
    <Card className="contentCard" id={props.id}>
      {props.header &&
        <Card.Header
          onClick={openContent}
          aria-controls="contentCardBody"
          aria-expanded={isContentOpen}
          className={`contentCardHeader ${!props.notCollapsable ? 'collapsable' : ''}`}
        >
          <h5>{props.header}</h5>
          {!props.notCollapsable && (
            <>
              {isContentOpen ? (
                <Icon icon="do_not_disturb_on" />
              ) : (
                <Icon icon="add_circle" />
              )}
            </>
          )}
        </Card.Header>
      }
      {props.notCollapsable ? (
        <Card.Body id="contentCardBody">
          {props.hint ? (
            <p className="hint">{props.hint}</p>
          ) : (
            <div></div>// add empty div for correct zebra if no hint
          )}
          {content}
        </Card.Body>
      ) : (
        <Collapse in={isContentOpen} >
          <Card.Body id="contentCardBody">
            {props.hint ? (
              <p className="hint">{props.hint}</p>
            ) : (
              <div></div>// add empty div for correct zebra if no hint
            )}
            {content}
          </Card.Body>
        </Collapse>
      )
      }
    </Card >
  )
}

export default ContentCard
