import { Col } from "react-bootstrap";
import Icon from "../Icon";

interface JaNeinProps {
  title?: string;
  data?: {
    vorhanden?: {},
    nichtVorhanden?: {},
    keineAngaben?: {},
    [key: string]: any
  };
  text?: string;
}


const JaNeinLine = (props: JaNeinProps) => {

  let keineAngabe = true
  let angabeText = 'Nicht angegeben'
  if (props.data?.vorhanden) {
    angabeText = 'Ja'
    keineAngabe = false
  }
  if (props.data?.nichtVorhanden) {
    angabeText = 'Nein'
    keineAngabe = false
  }

  return (
    <>
      <Col className="valign-wrapper">
        {props.data?.vorhanden &&
          <Icon icon="check_circle" />
        }
        {props.data?.nichtVorhanden &&
          <Icon icon="block" />
        }
        {keineAngabe &&
          <Icon icon="circle" style={{ color: '#E35D6A' }} />
        }
        {props.title}
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        {props.text ? props.text : angabeText}
      </Col>
    </>
  )
}

export default JaNeinLine
