export const baumhaekel =
{
  "Identifier" : "ad2c0347-7ffa-48da-a994-3f52e40da1da",
  "Spezialist" : {
    "vorhanden" : { },
    "Titel" : "Dr.",
    "Anrede" : "Herr",
    "Vorname" : "Magnus",
    "Nachname" : "Baumhäkel",
    "Profilbild" : {
      "URL" : "https://media.librecontent.org/files/9/d/9d6a244e-3ae2-417e-9802-a551f73eb712.jpg",
      "Beschreibung" : "PD Dr. Magnus Baumhäkel"
    },
    "Kontakt" : {
      "vorhanden" : { },
      "Telefon" : {
        "vorhanden" : { },
        "Rufnummer" : "0681 9590220"
      },
      "Fax" : {
        "vorhanden" : { },
        "Rufnummer" : "068195902240"
      },
      "EMail" : {
        "vorhanden" : { },
        "text" : "info@gefässpraxis.com"
      },
      "Homepage" : {
        "vorhanden" : { },
        "text" : "https://gefässpraxis-saarbrücken.de/"
      },
      "Mobil" : {
        "nichtVorhanden" : { }
      }
    }
  },
  "Berufsgruppe" : {
    "vorhanden" : { },
    "Name" : "Internisten",
    "Uuid" : "",
    "Kommentar" : ""
  },
  "Einleitung_Spezialist_Text" : {
    "vorhanden" : { },
    "text" : "Privatdozent Dr. med. Magnus Baumhäkel \nist Facharzt für Innere Medizin, Kardiologie und Angiologie und Vorstandsmitglied der Deutschen Gesellschaft für Mann und Gesundheit e.V.\n"
  },
  "Arztqualifikationen" : {
    "vorhanden" : { },
    "Arztgruppe" : {
      "vorhanden" : { },
      "Gruppen" : [ {
        "vorhanden" : { },
        "Bezeichnung" : "Internist"
      } ]
    },
    "Hauptqualifikation" : {
      "vorhanden" : { },
      "Bezeichnung" : "Facharzt Innere Medizin",
      "Uuid" : "uuid"
    },
    "Weiterbildungen" : {
      "vorhanden" : { },
      "Weiterbildungen" : [ {
        "vorhanden" : { },
        "Bezeichnung" : "Facharzt Innere Medizin und Angiologie",
        "Uuid" : "uuid"
      }, {
        "vorhanden" : { },
        "Bezeichnung" : "Facharzt Innere Medizin und Kardiologie",
        "Uuid" : "uuid"
      } ]
    },
    "Zusatzqualifikationen" : {
      "nichtVorhanden" : { }
    },
    "Sonstige_Qualifikationen" : {
      "nichtVorhanden" : { }
    }
  },
  "Taetigkeits_Schwerpunkte" : {
    "vorhanden" : { },
    "Taetigkeiten" : [ {
      "Bezeichnung" : "Gefäßultraschall",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D014463"
    }, {
      "Bezeichnung" : "ABI-Ankle-Brachial-Index (Knöchel-Arm-Index)",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D055109"
    }, {
      "Bezeichnung" : "VVP-Venenverschlussplethysmographie",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D010991"
    }, {
      "Bezeichnung" : "Kapillarmikroskopie",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D002196"
    }, {
      "Bezeichnung" : "LRR-Lichtreflexionsrheographie",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D014648"
    }, {
      "Bezeichnung" : "EKG - Elektrokardiogramm",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D004562"
    }, {
      "Bezeichnung" : "Belastungs-EKG (Ergometrie), Stressechokardiographie",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D016552"
    }, {
      "Bezeichnung" : "Langzeit-EKG & Langzeit-Blutdruckmessung",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D015716"
    }, {
      "Bezeichnung" : "Echokardiographie",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D004452"
    }, {
      "Bezeichnung" : "Lungenfunktionstest (Spirometrie)",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D012129"
    }, {
      "Bezeichnung" : "Schaufensterkrankheit, pAVK",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D058729"
    }, {
      "Bezeichnung" : "Krampfadern, chronisch venöse Insuffizienz",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D014648"
    }, {
      "Bezeichnung" : "Venenthrombose",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D020246"
    }, {
      "Bezeichnung" : "Schlaganfall, cAVK - cerebrale Durchblutungsstörungen",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D020521"
    }, {
      "Bezeichnung" : "Gefäßaussackung, Aneurysma",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D000783"
    }, {
      "Bezeichnung" : "Lymph- / Lipödem",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D008209"
    }, {
      "Bezeichnung" : "Funktionelle Durchblutungsstörungen",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D014652 "
    }, {
      "Bezeichnung" : "Koronare Herzkrankheit",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D00332"
    }, {
      "Bezeichnung" : "Herzschwäche (Herzinsuffizienz)",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D006333 "
    }, {
      "Bezeichnung" : "Herzrhythmusstörungen",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D001145"
    }, {
      "Bezeichnung" : "Herzklappenerkrankungen",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D006349"
    }, {
      "Bezeichnung" : "Bluthochdruck",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D006973"
    }, {
      "Bezeichnung" : "Cholesterin",
      "Uuid" : "http://purl.bioontology.org/ontology/MESH/D002784"
    } ]
  },
  "Spezialist_Vita" : {
    "vorhanden" : { },
    "Vita" : [ {
      "Von" : "2001",
      "Bis" : "2010",
      "Bezeichnung" : "Assistenzarzt",
      "Kommentar" : "Klinik für Innere Medizin III: Kardiologie, Angiologie, intern. Intensivmedizin Universitätsklinikum des Saarlandes"
    }, {
      "Von" : "2010",
      "Bis" : "2011",
      "Bezeichnung" : "Oberarzt",
      "Kommentar" : "Klinik für Innere Medizin III: Kardiologie, Angiologie, intern. Intensivmedizin Universitätsklinikum des Saarlandes"
    }, {
      "Von" : "2011",
      "Bis" : "2016",
      "Bezeichnung" : "Leitender Oberarzt",
      "Kommentar" : "Klinik für interventionelle Kardiologie / Angiologie CaritasKlinikum St. Theresia, Saarbrücken"
    }, {
      "Von" : "2016",
      "Bis" : "2018",
      "Bezeichnung" : "Leitender Oberarzt",
      "Kommentar" : "Klinik für Kardiologie, Pneumologie und Angiologie CaritasKlinikum St. Theresia, Saarbrücken Sektionsleiter Angiologie, Leiter Herzkatheterlabor Angiologische Privatambulanz"
    }, {
      "Von" : "2003",
      "Bezeichnung" : "Promotion"
    }, {
      "Von" : "2008",
      "Bezeichnung" : "Facharzt für Innere Medizin"
    }, {
      "Von" : "2010",
      "Bezeichnung" : "Facharzt für Angiologie"
    }, {
      "Von" : "2011",
      "Bezeichnung" : "Habilitation für das Fach Innere Medizin",
      "Kommentar" : "Klinik für Innere Medizin III (Prof. Dr. med. M. Böhm) Universitätsklinikum des Saarlandes, Homburg Saar"
    }, {
      "Von" : "2012",
      "Bis" : "2018",
      "Bezeichnung" : "Weiterbildungsbefugnis",
      "Kommentar" : "Angiologie – 24 Monate\nBasisweiterbildung Innere Medizin – 36 Monate"
    } ]
  },
  "Auszeichnungen" : {
    "vorhanden" : { }
  },
  "Weitere_Standorte" : {
    "vorhanden" : { },
    "Standorte" : [ {
      "Adresse" : {
        "vorhanden" : { },
        "Strasse" : "Zur Ostspange",
        "Hausnummer" : "8",
        "Postleitzahl" : "66111",
        "Ort" : "Saarbrücken",
        "Bundesland" : "Saarland"
      },
      "Anmerkung" : "Gefässpraxis Dr. Baumhäkel"
    } ]
  },
  "Medienpraesenz" : {
    "vorhanden" : { },
    "Medienpraesenz" : [ {
      "vorhanden" : { },
      "URL" : "https://gefässpraxis.com",
      "Kommentar" : "Homepage",
      "Name" : ""
    } ]
  },
  "Kooperationen" : {
    "nichtVorhanden" : { }
  },
  "Bilder" : {
    "vorhanden" : { },
    "Bilder" : [ {
      "vorhanden" : { },
      "URL" : "https://media.librecontent.org/files/9/d/9d6a244e-3ae2-417e-9802-a551f73eb712.jpg",
      "Uuid" : "PD Dr. Magnus Baumhäkel",
      "Beschreibung" : ""
    } ]
  },
  "Mitgliedschaften" : {
    "vorhanden" : { },
    "Mitglied" : [ {
      "Name" : "Vorstandsmitglied, 2. Vorsitzender, Deutsche Gesellschaft Mann und Gesundheit"
    }, {
      "Name" : "Deutsche Gesellschaft für Innere Medizin"
    }, {
      "Name" : "Deutsche Gesellschaft für Kardiologie"
    }, {
      "Name" : "Bund niedergelassener Kardiologen"
    }, {
      "Name" : "Deutsche Gesellschaft für Angiologie"
    }, {
      "Name" : "Arbeitsgemeinschaft Angiologie der DGK"
    }, {
      "Name" : "Berufsverband Deutscher Internisten"
    }, {
      "Name" : "Gutachterliche Tätigkeiten für nationale und internationale Fachzeitschriften"
    } ]
  },
  "Veroeffentlichungen" : {
    "nichtVorhanden" : { }
  }
}