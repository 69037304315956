import { elements } from "../../../types/Transparenzbericht";
import CardContent from "../components/Card/CardContent";
import Selbsteinschaetzung from "../components/Practice/Selbsteinschaetzung";
import ContentCard from "../components/ContentCard";
import SectionHeader from "../components/SectionHeader";

interface BarrierefreiheitProps {
  data: elements.barrierefreiheit.Barrierefreiheit | undefined
}

const Barrierefreiheit = (props: BarrierefreiheitProps) => {
  return (
    <>
      <SectionHeader title="C &ndash; Barrierefreiheit">
        <>
          <ContentCard
            header="Selbsteinschätzung"
            hint="Ist die Einrichtung für folgende Gruppen geeignet?"
            data={props.data?.Selbsteinschaetzung}
          >
            {props.data?.Selbsteinschaetzung?.vorhanden && (
              <Selbsteinschaetzung data={props.data?.Selbsteinschaetzung} />
            )}
          </ContentCard>
          <ContentCard
            header="Allgemeines zur Barrierefreiheit"
            hint="Welche der folgenden Maßnahmen zur Barrierereduktion bietet die Einrichtung?"
            data={props.data?.Barrierefreiheit_Allgemein}
          >
            <CardContent type="JaNein" title="Barrierefreie Beschilderung" data={props.data?.Barrierefreiheit_Allgemein?.Barrierefreie_Beschilderung} />
            <CardContent type="JaNein" title="Arbeit mit Piktogrammen" data={props.data?.Barrierefreiheit_Allgemein?.Piktogramme} />
            <CardContent type="JaNein" title="Alle Glasflächen und -Türen markiert" data={props.data?.Barrierefreiheit_Allgemein?.Glas_markiert} />
            <CardContent type="JaNein" title="Gebärdensprachkenntnisse vorhanden" data={props.data?.Barrierefreiheit_Allgemein?.Gebaerdesprache} />
            <CardContent type="JaNein" title="Infomaterial in einfacher Sprache" data={props.data?.Barrierefreiheit_Allgemein?.Einfache_Informationen} />
          </ContentCard>
          <ContentCard
            header="Barrierefreiheit im Eingangsbereich: Treppen"
            hint="Wie ist die bauliche Situation im Eingangsbereich bezüglich Treppen?"
            data={props.data?.Eingangsbereich}
          >
            <CardContent type="JaNein" title="Einrichtung ebenerdig" data={props.data?.Eingangsbereich?.Ebene?.Ebenerdig} />
            <CardContent type="JaNein" title="Stockwerk" data={props.data?.Eingangsbereich?.Ebene?.Stockwerk} text={props.data?.Eingangsbereich?.Ebene?.Stockwerk?.stockwerke?.toString()} />
            <CardContent type="JaNein" title="Treppenstufen vorhanden" data={props.data?.Eingangsbereich?.Treppe} />
            <CardContent type="JaNein" title="Türschwelle vorhanden (≥ 7cm)" data={props.data?.Eingangsbereich?.Tuerschwelle?.kleineSchwelle} />
          </ContentCard>
          <ContentCard
            header="Barrierefreiheit im Eingangsbereich: Aufzug"
            hint="Wie ist die bauliche Situation im Eingangsbereich bezüglich Aufzug?"
            data={props.data?.Eingangsbereich?.Aufzug}
          >
            <CardContent type="JaNein" title="Aufzug vorhanden" data={props.data?.Eingangsbereich?.Aufzug} />
            <CardContent type="JaNein" title="ausreichend Platz zum Rangieren" data={props.data?.Eingangsbereich?.Aufzug?.Rollstuhl?.grosserAufzug} />
            <CardContent type="JaNein" title="Bedienelemente in niedriger Höhe" data={props.data?.Eingangsbereich?.Aufzug?.Rollstuhl?.bedienElementeNiedrig} />
            <CardContent type="JaNein" title="Türbreite ≥ 90cm" data={props.data?.Eingangsbereich?.Aufzug?.Rollstuhl?.breiteTuerFahrstuhl} />
            <CardContent type="JaNein" title="akustische Ansage" data={props.data?.Eingangsbereich?.Aufzug?.Gerhoerlose?.visuelleUnterstuetzung} />
            <CardContent type="JaNein" title="Beschriftung in Brailleschrift" data={props.data?.Eingangsbereich?.Aufzug?.Sehvermoegen_eingeschraenkt?.brailleschrift} />
            <CardContent type="JaNein" title="Visuelle Anzeigen" data={props.data?.Eingangsbereich?.Aufzug?.Sehvermoegen_eingeschraenkt?.akustischeUnterstuetzung} />
          </ContentCard>
          <ContentCard
            header="Barrierefreiheit im Behandlungszimmer"
            hint="Welche der folgenden Maßnahmen zur Barrierereduktion bieten die Behandlungszimmer?"
            data={props.data?.Barrierefreiheit_Behandlungszimmer}
          >
            <CardContent type="JaNein" title="Bestuhlung mit Armlehnen" text={"kein feld"} />
            <CardContent type="JaNein" title="Türbreite ≥ 90cm" text={"kein feld"} />
            <CardContent type="JaNein" title="Untersuchungsmöbel höhenverstellbar" data={props.data?.Barrierefreiheit_Behandlungszimmer?.Hoehenverstellbare_Moebel} />
            <CardContent type="JaNein" title="ausreichend Rangiermöglichkein" data={props.data?.Barrierefreiheit_Behandlungszimmer?.Rangier_Moeglichkeiten} />
          </ContentCard>
          <ContentCard
            header="Barrierefreiheit im WC"
            hint="Wie ist die bauliche Situation im Eingangsbereich bezüglich Aufzug?"
            data={props.data?.WC}
          >
            <CardContent type="JaNein" title="WC vorhanden" data={props.data?.WC} />
            <CardContent type="JaNein" title="Notruf im WC" data={props.data?.WC?.notruf} />
            <CardContent type="JaNein" title="Türen von außen entriegelbar" data={props.data?.WC?.tuerentriegelung} />
            <CardContent type="JaNein" title="Bedienelemente sitzend erreichbar" data={props.data?.WC?.erreichbarkeit} />
            <CardContent type="JaNein" title="Handläufe vorhanden" data={props.data?.WC?.handlauf} />
            <CardContent type="JaNein" title="ausreichend Rangiermöglichkeiten" data={props.data?.WC?.rangierplatz} />
            <CardContent type="JaNein" title="Waschbecken unterfahrbar" data={props.data?.WC?.waschbecken} />
          </ContentCard>
          <ContentCard
            header="Ergänzende Angaben zur Barrierefreiheit"
            hint="Sofern die Einrichtung weitere Maßnahmen zur Barrierefreiheit ergriffen hat."
            data={props.data?.Zusatz}
          >
            {props.data?.Zusatz?.vorhanden && (
              props.data.Zusatz?.zusatz?.map((zusatz, index) =>
                <CardContent type="JaNein" key={index} title={zusatz.Ergaenzung} />
              )
            )}
          </ContentCard>
        </>
      </SectionHeader>
    </>
  )
}

export default Barrierefreiheit
