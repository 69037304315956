import { Row } from "react-bootstrap";
import JaNeinLine from "./JaNeinLine";
import LinkLine from "./LinkLine";
import ListLine from "./ListLine";
import TextLine from "./TextLine";
import { CSSProperties } from "react";

interface CardContentProps {
  title?: string;
  data?: {
    vorhanden?: {},
    nichtVorhanden?: {},
    keineAngaben?: {},
    [key: string]: any
  };
  text?: string;
  type: 'JaNein' | 'Text' | 'List' | 'Link';
  linkTo?: string;
  tab?: number;
}

const CardContent = (props: CardContentProps) => {
  const style: CSSProperties = {}
  if (props.tab) {
    style.paddingLeft = (props.tab * 1.25) + "rem";
  }

  return (
    <Row className="cardContentLine" style={style}>
      {props.type === 'JaNein' &&
        <JaNeinLine {...props} />
      }
      {props.type === 'Text' &&
        <TextLine {...props} />
      }
      {props.type === 'List' &&
        <ListLine {...props} />
      }
      {props.type === 'Link' &&
        <LinkLine linkTo={props.linkTo ? props.linkTo : ''} {...props} />
      }
    </Row>
  )
}

export default CardContent
