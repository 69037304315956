import { Col, Container, Nav, NavDropdown, Navbar, Offcanvas, Row } from "react-bootstrap";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import * as Constants from '../../../Constants';
import { ArticleType } from "../../../types/Types";
import ToC from "../components/ToC";
import TitleBar from "../components/TitleBar";


export const loader = async ({ params }: LoaderFunctionArgs): Promise<ArticleType> => {
  let fetchURL = Constants.SERVICEURL + 'article/' + params.articleId;
  if (params.parentId) {
    fetchURL += '/' + params.parentId;
  }

  const article = await fetch(fetchURL)
    .then(response => response.json());
  if (article) return article;
  throw new Error("articleId not set")
}

const Article = () => {

  const article = useLoaderData() as ArticleType
  return (
    <>
      <TitleBar title={article.parent ? article.parent.title : article.title} />
      <Container>
        <Navbar bg="grey" expand={'md'} className="articleNav">
          <Container>
            <Navbar.Toggle aria-controls="offcanvasArticleNav" />
            <Navbar.Offcanvas
              id="offcanvasArticleNav"
              aria-labelledby="offcanvasArticleNav"
              placement="end">
              <Offcanvas.Header closeButton />
              <Offcanvas.Body>
                <Nav>
                  <Nav.Link href={`/article/${article.parent ? article.parent.id : article.id}`} className={article?.label?.toLowerCase().includes('übersicht') ? 'active' : ''}>Übersicht</Nav.Link>
                  {article?.detail?.length > 0 &&
                    <NavDropdown title="Mehr Wissen" className={article?.label?.toLowerCase().includes('wissen') ? 'active' : ''}>
                      {article?.detail.map((detail: { id: number, title: string }) =>
                        <NavDropdown.Item key={detail.id} href={`/article/${detail.id}`}>{detail.title}</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  }
                  {article?.background?.length > 0 &&
                    <NavDropdown title="Was Studien sagen" className={article?.label?.toLowerCase().includes('research') ? 'active' : ''}>
                      {article?.background.map((background: { id: number, title: string }) =>
                        <NavDropdown.Item key={background.id} href={`/article/${background.id}`}>{background.title}</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  }
                  {article?.report?.length > 0 &&
                    <NavDropdown title="Erfahrungsberichte" className={article?.label?.toLowerCase().includes('erfahrung') ? 'active' : ''}>
                      {article?.report.map((report: { id: number, title: string }) =>
                        <NavDropdown.Item key={report.id} href={`/article/${report.id}`}>{report.title}</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  }
                  {article?.extras?.length > 0 &&
                    <NavDropdown title="Extras" className={article?.label?.toLowerCase().includes('extras') ? 'active' : ''}>
                      {article?.extras.map((extra: { id: number, title: string }) => {
                        return <NavDropdown.Item key={extra.id} href={`/article/${extra.id}/${article.parent.id}`}>{extra.title}</NavDropdown.Item>
                      }
                      )}
                    </NavDropdown>
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <Row className="articleContent">
          <Col>
            <h2 className="articleTitle">{article.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: article.html }} />
            <div>
              <div className="publisher">Herausgeber: <p>Institut für Qualität und Wirtschaftlichkeit im Gesundheitswesen (IQWiG)</p></div>
              <div className="sourcelink-page"><a href={article.ref.replace('atom.xml', 'html')} target="_blank" rel="noreferrer">Originalseite auf gesundheitsinformation.de</a></div>
              <div className="logo-large-green"><img src="https://www.gesundheitsinformation.de/grafiken/content-partner/de_logo_green.png" width="234" height="53" alt="Logo Gesundheitsinformation.de" /></div>
            </div>
          </Col>
          <Col md={3}>
            <ToC />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Article