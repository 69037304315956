import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const EditModal = () => {
  const [show, setShow] = useState(false)
  const generateInput = () => {
    let element = document.getElementById("editModal")
    let value = Math.round(Math.random() * 100)
    if (element)
      element.innerHTML = `<input type="text" value="${value}" />`
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>Modal</Button>
      <Modal show={show} onHide={() => setShow(false)} onEnter={() => generateInput()}>
        <Modal.Header closeButton>
          <Modal.Title>titel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="editModal"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditModal
