import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Footer from "../Footer/Footer"
import Content from "../Content/components/Content"
import Header from "../Header/Header"
import jwt_decode from "jwt-decode";

const Auth = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    checkUserToken()
  }, [isLoggedIn])


  const checkUserToken = () => {
    const token = localStorage.getItem('user-token')
    if (!token || (token && isTokenExpired(token))) {
      setIsLoggedIn(false)
      return navigate('/auth');
    }
    setIsLoggedIn(true)
  }

  const isTokenExpired = (token: string) => {
    try {
      const decoded: any = jwt_decode(token)
      return decoded.exp < Date.now() / 1000
    } catch (e) {
      return true;
    }
  }

  return (
    <>
      {isLoggedIn && (
        <>
          <Header />
          <Content />
          <Footer />
        </>
      )}
    </>
  )
}

export default Auth
