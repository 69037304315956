import { Col, Container, Row } from "react-bootstrap";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { baumhaekel } from '../../../json/Practice_Baumhaekel';
import { lasar } from '../../../json/Practice_LASAR';
import { oralchirurgie } from '../../../json/Practice_Oralchirurgie';
import { PracticeType } from "../../../types/Types";
import TitleBar from "../components/TitleBar";
import ToC from "../components/ToC";
import Barrierefreiheit from "./Barrierefreiheit";
import Kompetenz from "./Kompetenz";
import Patientenservice from "./Patientenservice";
import Patientensicherheit from "./Patientensicherheit";

export const loader = async ({ params }: LoaderFunctionArgs): Promise<PracticeType> => {
  const json = lasar;
  const practice = {
    id: 1,
    title: json.Strukturdaten.Name_Einrichtung.Name,
    json: json,
  }

  if (params.practiceId === '2') {
    // const oralchirurgieJson = oralchirurgie;
    // const oralchirurgiePractice = {
    //   id: 4,
    //   title: oralchirurgieJson.Strukturdaten.Name_Einrichtung.Name,
    //   json: oralchirurgieJson,
    // }
    // return oralchirurgiePractice
    throw new Error("falsches/veraltetes JSON-Format")
  }

  if (params.practiceId === '4') {
    const baumhaekelJson = baumhaekel;

    const baumhaekelPractice = {
      id: 4,
      title: baumhaekelJson.Strukturdaten.Name_Einrichtung.Name,
      json: baumhaekelJson,
    }
    return baumhaekelPractice
  }

  // const practice = await fetch(Constants.SERVICEURL + 'organization/' + params.officeId)
  //   .then(response => response.json());
  return practice;
}

const Practice = () => {

  const practice = useLoaderData() as PracticeType

  return (
    <>
      <TitleBar title={practice.title} />
      <Container>
        <Row>
          <Col>
            <Patientenservice data={practice.json} />
            <Kompetenz data={practice.json?.Kompetenzen} strukturData={practice.json?.Strukturdaten} />
            <Barrierefreiheit data={practice.json?.Barrierefreiheit} />
            <Patientensicherheit data={practice.json?.Patientensicherheit} />
          </Col>
          <Col md={3}>
            <ToC />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Practice
