import { Container } from 'react-bootstrap';

interface TitleBarProps {
  title: string;
}

const TitleBar = (props: TitleBarProps) => {
  return (
    <div className="titleBar">
      <Container>
        <h2>{props.title}</h2>
      </Container>
    </div>
  )
}

export default TitleBar
