import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { SearchResultType } from "../../../types/Types"

interface SpecialistResultProps {
  data: SearchResultType[]
}

const SpecialistResult = (props: SpecialistResultProps) => {
  return (
    <>
      <Row>
        <Col>
          <Card className="contentCard">
            <Card.Header>
              <Row>
                <Col md={3}>
                  Nachname, Vorname
                </Col>
                <Col md={5}>
                  Berufsbezeichnung / Qualifikation
                </Col>
                <Col md={4}>
                  Bundesland
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {
                // add empty div for correct zebra
              }
              <div></div>
              {props.data.map((res, index) => (
                <Row key={index} className="cardContentLine">
                  <Col md={3}>
                    <Link to={`/specialist/${res.id}`} className="truncateText">{res.title}</Link>
                  </Col>
                  <Col md={5} className="truncateText">
                    {res.profession}
                  </Col>
                  <Col md={4} className="truncateText">
                    {res.state}
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SpecialistResult
