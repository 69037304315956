import { useState } from "react";
import { Col, Image, Modal, Row } from "react-bootstrap";
import { elements } from "../../../types/Transparenzbericht";

interface ImageSlideshowProps {
  data: elements.Bilder | undefined;
  fillEmpty?: boolean;
}

const ImageSlideshow = (props: ImageSlideshowProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [activeImage, setActiveImage] = useState<string | null>(null)

  const handleShowModal = (img: string) => {
    setActiveImage(img)
    if (img)
      setShowModal(true)
  }

  const handleCloseModal = () => {
    setActiveImage(null)
    setShowModal(false)
  }
 
  const images = (props.data?.Bilder ? props.data?.Bilder?.map(bild => bild.URL) : []) as string[]

  return (
    <>
      <Row className="imageSlideshow">
        <Col>
          {images[0] &&
            <Image
              src={images[0]}
              alt="Bild der Einrichtung"
              height={360}
              width={539}
              onClick={() => handleShowModal(images[0])}
            />
          }
        </Col>
        <Col>
          <Row>
            <Col md={6}>
              {images[1] ? (
                <Image
                  src={images[1]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[1])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
            <Col md={6}>
              {images[2] ? (
                <Image
                  src={images[2]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[2])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
            <Col md={6}>
              {images[3] ? (
                <Image
                  src={images[3]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[3])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
            <Col md={6}>
              {images[4] ? (
                <Image
                  src={images[4]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[4])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
            <Col md={6}>
              {images[5] ? (
                <Image
                  src={images[5]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[5])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
            <Col md={6}>
              {images[6] ? (
                <Image
                  src={images[6]}
                  alt="Bild der Einrichtung"
                  height={100}
                  width="100%"
                  onClick={() => handleShowModal(images[6])}
                />
              ) : (
                !props.fillEmpty &&
                <div className="noImageAvailable"></div>
              )}
            </Col>
          </Row>
        </Col>
      </Row >
      <Modal
        className="imageSlideshowModal"
        dialogClassName="imageSlideshowDialog"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body >
          {activeImage &&
            <Image src={activeImage} onClick={handleCloseModal} />
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ImageSlideshow
