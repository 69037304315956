
interface ListProps {
  text?: string | undefined;
}

const ListLine = (props: ListProps) => {
  return (
    <span className="listLine">
      <span className="listLineDot">&bull;</span>
      &ensp;{props.text}
    </span>
  )
}

export default ListLine
