import { Col, Container, Image, Row } from "react-bootstrap"
import { Link, useRouteError } from 'react-router-dom'
import errorImg from '../../../assets/images/error_1.png'

const Error = () => {
  const error = useRouteError()

  return (
    <Container className="contentContainer">
      <Row>
        <Col style={{ textAlign: "center" }}>
          <Image src={errorImg} alt="something went wrong!" />
          <br />
          <br />
          <div style={{color: '#138383'}}>{error.message}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/`} className="btn btn-primary">Zurück zur Startseite</Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Error