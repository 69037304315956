import { Col, Row } from "react-bootstrap"
import { elements } from "../../../../types/Transparenzbericht"
import Icon from "../Icon"

interface SelbsteinschaetzungEintragProps {
  data: elements.barrierefreiheit.SelbsteinschaetzungEintrag | undefined,
  icon: string,
  text: string,
}

const SelbsteinschaetzungEintrag = (props: SelbsteinschaetzungEintragProps) => {

  const eignung: any = {
    BEDINGTGEEIGNET: 'bedingt geeignet',
    GEEIGNET: 'geeignet',
    UNGEIGNET: 'ungeeignet',
  }

  let eignungText = 'Nicht angegeben'
  if (props.data?.vorhanden) {
    eignungText = props.data?.Eignung ? eignung[props.data.Eignung] : 'Ja'
  }
  if (props.data?.nichtVorhanden) {
    eignungText = 'Nein'
  }

  return (
    <Row className="cardContentLine" >
      <Col className="valign-wrapper">
        <Icon icon={props.icon} />
        {props.text}
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        {eignungText}
      </Col>
    </Row>
  )
}

export default SelbsteinschaetzungEintrag
