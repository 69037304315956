import { elements } from '../../../../types/Transparenzbericht'
import SelbsteinschaetzungEintrag from "./SelbsteinschaetzungEintrag"

interface SelbsteinschaetzungProps {
  data?: elements.barrierefreiheit.Selbsteinschaetzung
}

const Selbsteinschaetzung = (props: SelbsteinschaetzungProps) => {
  return (
    <>
      <SelbsteinschaetzungEintrag data={props.data?.Rollstuhlfahrer} text="Menschen mit Rollstuhl" icon="accessible" />
      <SelbsteinschaetzungEintrag data={props.data?.Eingeschraenkte_Mobilitaet} text="Menschen mit Mobilitätseinschränkung" icon="assist_walker" />
      <SelbsteinschaetzungEintrag data={props.data?.Blinde} text="blinde Menschen" icon="blind" />
      <SelbsteinschaetzungEintrag data={props.data?.Gehoerlos} text="gehörlose Menschen" icon="hearing_disabled" />
    </>
  )
}

export default Selbsteinschaetzung
