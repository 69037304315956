import { useEffect, useState } from "react"
import { ToCType } from "../../types/Types"


const useGenerateToC = () => {
  const [headings, setHeadings] = useState<ToCType[]>([])

  useEffect(() => {
    let elements = Array.from(document.querySelectorAll('h4')) as HTMLDivElement[]
    const heads = elements.map((el, index) => {
      if (!el.getAttribute('id')) {
        el.setAttribute('id', index.toString())
      }
      let { textContent: title, id } = el
      title = title == null ? '' : title.replaceAll('\n', '').trim()
      return { id, title }
    })
    setHeadings(heads)
  }, [])

  return headings
}

export default useGenerateToC