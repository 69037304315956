import { Col, Row } from "react-bootstrap";
import Icon from "../Icon";
import { elements } from "../../../../types/Transparenzbericht";

interface KontaktCardProp {
  adresse?: elements.Adresse;
  kontakt?: elements.Kontakt;
}

const KontaktCard = (props: KontaktCardProp) => {
  return (
    <>
      {props.adresse && (
        <Row>
          <Col className="valign-wrapper" style={{ alignItems: "flex-start" }}>
            <Icon icon="location_on" />
            {props.adresse?.Strasse} {props.adresse?.Hausnummer}
            <br />
            {props.adresse?.Postleitzahl} {props.adresse?.Ort}
          </Col>
        </Row>
      )}
      <Row>
        <Col className="valign-wrapper">
          <Icon icon="call" />
          {props.kontakt?.Telefon?.vorhanden ? (
            (props.kontakt?.Telefon.Vorwahl ? props.kontakt?.Telefon.Vorwahl : '')
            + ' ' + props.kontakt?.Telefon.Rufnummer
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row>
        <Col className="valign-wrapper">
          <Icon icon="smartphone" />
          {props.kontakt?.Mobil?.vorhanden ? (
            (props.kontakt?.Mobil?.Vorwahl ? props.kontakt?.Mobil.Vorwahl : '')
            + ' ' + props.kontakt?.Mobil?.Rufnummer
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row>
        <Col className="valign-wrapper">
          <Icon icon="fax" />
          {props.kontakt?.Fax?.vorhanden ? (
            (props.kontakt?.Fax?.Vorwahl ? props.kontakt?.Fax.Vorwahl : '')
            + ' ' + props.kontakt?.Fax?.Rufnummer
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row>
        <Col className="valign-wrapper">
          <Icon icon="mail" />
          {props.kontakt?.EMail?.vorhanden ? (
            <a href={"mailto:" + props.kontakt?.EMail?.text}>{props.kontakt?.EMail?.text}</a>
          ) : (
            '-'
          )}
        </Col>
      </Row>
    </>
  )
}

export default KontaktCard
