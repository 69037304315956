import { Outlet } from "react-router-dom"
import ScrollToTop from "./ScrollToTop"

const Content = () => {
  return (
    <div className="contentContainer">
      <ScrollToTop />
      <Outlet />
    </div>
  )
}

export default Content
