import { FormikHelpers } from "formik"
import { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import ScrollTopButton from "../components/ScrollTopButton"
import OrganizationResult from "./OrganizationResult"
import SearchForm, { formValues } from "./SearchForm"
import SpecialistResult from "./SpecialistResult"
import * as Constants from '../../../Constants';
import { SearchResultType } from "../../../types/Types"

const Search = () => {
  const [searchTerm, setSearchTerm] = useState<formValues | null>(null)
  const [result, setResult] = useState<SearchResultType[]>([])
  const [maxResults, setMaxResults] = useState<number>(0)
  const [offset, setOffset] = useState<number>(0)

  const handleSubmit = async (values: formValues, { setSubmitting }: FormikHelpers<formValues>) => {
    const res = await fetch(Constants.SERVICEURL + 'search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...values, resultStart: 0, resultCount: Constants.searchResultCount })
    })
      .then(response => response.json());
    const sortedResults = res.result
    sortedResults.sort((el1: SearchResultType, el2: SearchResultType) => {
      const title1 = el1.title
      const title2 = el2.title
      if (title1 < title2) return -1
      if (title1 > title2) return 1
      return 0
    })

    setOffset(Constants.searchResultCount)
    setSearchTerm(values)
    setMaxResults(res.max_result)
    setResult(sortedResults)
  }

  const handleMoreResults = async () => {
    const moreResults = await fetch(Constants.SERVICEURL + 'search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...searchTerm, resultStart: offset, resultCount: Constants.searchResultCount })
    })
      .then(response => response.json());

    const sortedResults = moreResults.result
    sortedResults.sort((el1: SearchResultType, el2: SearchResultType) => {
      const title1 = el1.title
      const title2 = el2.title
      if (title1 < title2) return -1
      if (title1 > title2) return 1
      return 0
    })

    setResult([...result, ...sortedResults])
    setOffset(offset + Constants.searchResultCount)
  }

  return (
    <>
      <SearchForm handleSubmit={handleSubmit} />
      {searchTerm &&
        <Container>
          <div className="searchResultHeader">
            <Row>
              <Col>
                <h3>{searchTerm.searchTerm}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{maxResults} Ergebnisse</p>
              </Col>
            </Row>
          </div>
          {searchTerm.entity_type === Constants.entityType.ORGANIZATION && result.length > 0 &&
            <OrganizationResult data={result} />
          }
          {searchTerm.entity_type === Constants.entityType.SPECIALIST && result.length > 0 &&
            <SpecialistResult data={result} />
          }
          <div className="searchMoreResults">
            <Row className="justify-content-md-center">
              <Col md="auto">
                <p>{result.length} von {maxResults} Ergebnisse</p>
              </Col>
            </Row>
            {result.length < maxResults && (
              <Row className="justify-content-md-center">
                <Col md="auto">
                  <Button onClick={handleMoreResults} variant="secondary">Mehr anzeigen</Button>
                </Col>
              </Row>
            )}
          </div>
          <ScrollTopButton />
        </Container>
      }
    </>
  )
}

export default Search
