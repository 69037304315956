import { useState } from "react"
import { Button } from "react-bootstrap"
import Icon from "./Icon"

const ScrollTopButton = () => {
  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisibility = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  window.addEventListener('scroll', toggleVisibility)

  return (
    <>
      {visible && (
        <div className="scrollTopContainer">
          <Button className="scrollTopButton" onClick={scrollToTop}>
            <Icon icon="arrow_upward" />
          </Button>
        </div>
      )}
    </>
  )
}

export default ScrollTopButton
