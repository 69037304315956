import { Col, Image, Row } from "react-bootstrap";
import { elements } from "../../../types/Transparenzbericht";
import Ampel from "../components/Ampel";
import CardContent from "../components/Card/CardContent";
import ContentCard from "../components/ContentCard";

interface UeberMichProps {
  data: elements.TransparenzXmlSpezialist | undefined;
  name: string
}

const UeberMich = (props: UeberMichProps) => {

  const image = props.data?.Bilder?.vorhanden ? props.data?.Bilder?.Bilder?.at(0)?.URL : 'no_Image.png'

  return (
    <>
      <Row>
        <Col md={4} style={{ marginBottom: '2rem' }}>
          <Ampel />
        </Col>
      </Row>
      <Row className="imageSlideshow" style={{ marginBottom: '2rem' }}>
        <Col md={4}>
          <Image
            src={image}
            alt="Bild des Spezialisten"
            style={{ width: '100%' }}
          />
        </Col>
        <Col md={8}>
          <ContentCard
            header={props.name}
            notCollapsable
          >
            {props.data?.Arztqualifikationen?.Hauptqualifikation?.vorhanden && (
              <CardContent type="List" text={props.data?.Arztqualifikationen?.Hauptqualifikation?.Bezeichnung} />
            )}
            {props.data?.Arztqualifikationen?.Weiterbildungen?.vorhanden && (
              props.data?.Arztqualifikationen?.Weiterbildungen?.Weiterbildungen?.map((quali, index) =>
                <CardContent key={index} type="List" text={quali.Bezeichnung} />
              )
            )}
            {props.data?.Arztqualifikationen?.Zusatzqualifikationen?.vorhanden && (
              props.data?.Arztqualifikationen.Zusatzqualifikationen.zusatzqualifikation?.map((zusatz, index) =>
                <CardContent key={index} type="List" text={zusatz.Kommentar} />
              )
            )}
          </ContentCard>
        </Col>
      </Row>

    </>
  )
}

export default UeberMich
