import { Col, Row } from 'react-bootstrap';
import { elements } from "../../../../types/Transparenzbericht";

interface SprechstundenCardProps {
  data?: elements.strukturdaten.Sprechstunden
}

const SprechstundenCard = (props: SprechstundenCardProps) => {
  let sprechstunden = {
    Montag: [],
    Dienstag: [],
    Mittwoch: [],
    Donnerstag: [],
    Freitag: [],
    Samstag: [],
    Sonntag: []
  };

  if (props.data?.Sprechstunde) {
    sprechstunden = props.data.Sprechstunde.reduce((accu: any, curr: any) => {
      const time = <>{curr.Von} &ndash; {curr.Bis}</>
      return {
        ...accu,
        [curr.Tag]: [...(accu[curr.Tag] || []), time]
      }
    }, sprechstunden)
  }

  return (
    <>
      <Row className="cardContentLine">
        <Col md={2}>
          Montag
        </Col>
        {sprechstunden.Montag.length > 0 ? (
          sprechstunden.Montag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Dienstag
        </Col>
        {sprechstunden.Dienstag.length > 0 ? (
          sprechstunden.Dienstag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Mittwoch
        </Col>
        {sprechstunden.Mittwoch.length > 0 ? (
          sprechstunden.Mittwoch.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Donnerstag
        </Col>
        {sprechstunden.Donnerstag.length > 0 ? (
          sprechstunden.Donnerstag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Freitag
        </Col>
        {sprechstunden.Freitag.length > 0 ? (
          sprechstunden.Freitag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Samstag
        </Col>
        {sprechstunden.Samstag.length > 0 ? (
          sprechstunden.Samstag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
      <Row className="cardContentLine">
        <Col md={2}>
          Sonntag
        </Col>
        {sprechstunden.Sonntag.length > 0 ? (
          sprechstunden.Sonntag.map((time, index) =>
            <Col key={index} md={2}>{time}</Col>
          )) : (
          <Col md={2}>geschlossen</Col>
        )}
      </Row>
    </>
  )
}

export default SprechstundenCard
