import { elements } from "../../../types/Transparenzbericht";
import CardContent from "../components/Card/CardContent";
import ContentCard from "../components/ContentCard";
import SectionHeader from "../components/SectionHeader";

interface PatientensicherheitProps {
  data: elements.patientensicherheit.Patientensicherheit | undefined
}

const Patientensicherheit = (props: PatientensicherheitProps) => {
  return (
    <>
      <SectionHeader title="D &ndash; Patientensicherheit">
        <>
          <ContentCard
            header="Personal und Beschwerdemanagement"
            hint="Welche der folgenden Maßnahmen wurden im Bezug auf die Mitarbeitenden getroffen?"
            data={props.data?.Regelung_Beschwerdemanagement}
          >
            <CardContent type="JaNein" title="Verantwortlicher für Patientensicherheit bestimmt" data={props.data?.Verantwortlicher_Patientensicherheit} />
            <CardContent type="JaNein" title="Personal geschult im Umgang mit eGK" data={props.data?.Mitarbeiterschulungen?.EGK} />
            <CardContent type="JaNein" title="Personal geschult im Umgang mit ePA" data={props.data?.Mitarbeiterschulungen?.EPA} />
            <CardContent type="JaNein" title="Beschwerdemanagement geregelt" data={props.data?.Regelung_Beschwerdemanagement} />
            <CardContent tab={1} type="JaNein" title="In schriftlicher Form" data={props.data?.Regelung_Beschwerdemanagement?.Regelung_Beschwerdemanagement_schriftlich} />
            <CardContent tab={1} type="JaNein" title="In mündlicher Form" data={props.data?.Regelung_Beschwerdemanagement?.Regelung_Beschwerdemanagement_muendlich} />
          </ContentCard>
          <ContentCard
            header="Notfall- und Risikomanagement"
            hint="Welche der folgenden Maßnahmen zum Risikomanagement wurden getroffen?"
            data={props.data?.Regelung_Notfall_RisikoManagement}
          >
            <CardContent type="JaNein" title="Regelungen für medizinische Notfälle" data={props.data?.Regelung_Notfall_RisikoManagement} />
            <CardContent tab={1} type="JaNein" title="Vollständigkeit und Aktualität der Notfallausstattung" data={props.data?.Regelung_Notfall_RisikoManagement?.Kontrolle_Notfallaustattung} />
            <CardContent tab={1} type="JaNein" title="Erkennung von Notfällen bei tel. Kontakt" data={props.data?.Regelung_Notfall_RisikoManagement?.Kriterien_Telefon_Kritisch} />
            <CardContent tab={1} type="JaNein" title="Erkennung von Notfällen bei Patienten" data={props.data?.Regelung_Notfall_RisikoManagement?.Notfall_Erkennungstraining} />
            <CardContent tab={1} type="JaNein" title="Durchführung von Notfalltrainings" data={props.data?.Regelung_Notfall_RisikoManagement?.Notfalltraining} />
            <CardContent tab={1} type="JaNein" title="Analyse auf Sturzrisiken" text={"kein feld"} />
          </ContentCard>
          <ContentCard
            header="Fehlervermeidung"
            hint="Welche der folgenden Maßnahmen zur Fehlervermeidung wurden getroffen?"
            data={props.data?.Fehlervermeidung_Checklisten}
          >
            <CardContent type="JaNein" title="Führen von Checklisten" data={props.data?.Fehlervermeidung_Checklisten} />
            <CardContent type="JaNein" title="Strukturierte Anamnese" data={props.data?.Fehlervermeidung_Checklisten?.Strukturierte_Anamnese} />
            <CardContent type="JaNein" title="Einschätzung der Dringlichkeit zur Terminvergabe" data={props.data?.Fehlervermeidung_Checklisten?.Dringlichkeit_Terminvergabe} />
            <CardContent type="JaNein" title="Informationsübergabe zur Einweisung ins Krankenhaus" data={props.data?.Fehlervermeidung_Checklisten?.Ueberweisungsvorgang} />
            <CardContent type="JaNein" title="Vorbereitung von operativen Eingriffen" data={props.data?.Fehlervermeidung_Checklisten?.Operative_Eingriffe} />
            <CardContent type="JaNein" title="Schnittstellenmanagement" data={props.data?.Schnittstellen_Management} />
            <CardContent tab={1} type="JaNein" title="mit Ärzten und Therapeuten" data={props.data?.Schnittstellen_Management?.Kommunikation_vor_nach_Behandler} />
            <CardContent tab={1} type="JaNein" title="mit Apotheken" data={props.data?.Schnittstellen_Management?.Kontakt_Apotheken} />
            <CardContent tab={1} type="JaNein" title="mit Laboren, externer Pathologie, etc." data={props.data?.Schnittstellen_Management?.Kontakt_Bereiche_ohne_Patientenkontakt} />
            <CardContent tab={1} type="JaNein" title="mit Pflegeeinrichtungen" data={props.data?.Schnittstellen_Management?.Kontakt_Pflege} />
            <CardContent tab={1} type="JaNein" title="bei Einweisung in stationäre Behandlung" data={props.data?.Schnittstellen_Management?.Schnittstelle_stationaere_Behandlung} />
            <CardContent type="JaNein" title="Fehlermeldesystem vorhanden" data={props.data?.Fehlermeldesysteme} />
          </ContentCard>
          <ContentCard
            header="Hygienemaßnahmen"
            hint="Welche Hygienemaßnahmen wurden getroffen?"
            data={props.data?.Hygienemassnahmen}
          >
            <CardContent type="JaNein" title="Verantwortlicher für Hygienemaßnahmen bestimmt" data={props.data?.Hygienemassnahmen?.Verantwortlicher_Hygienemassnahmen} />
            <CardContent type="JaNein" title="Umsetzung der KRINKO-Empfehlungen" data={props.data?.Hygienemassnahmen?.KRINKO_Empfehlungen} />
            <CardContent type="JaNein" title="Hygieneplan vorhanden" data={props.data?.Hygienemassnahmen?.Hygieneplan} />
            <CardContent type="JaNein" title="Verhütung nosokomialer Infektionen" data={props.data?.Hygienemassnahmen?.Hygieneplan?.Verhuetung_nosokomialer_Infektionen} />
            <CardContent type="JaNein" title="Händehygiene" data={props.data?.Hygienemassnahmen?.Hygieneplan?.Haendehygiene} />
            <CardContent type="JaNein" title="Antibiotikaprophylaxe" data={props.data?.Hygienemassnahmen?.Hygieneplan?.Antibiotikaprophylaxe} />
            <CardContent type="JaNein" title="Schulungen zu Hygienemaßnahmen" data={props.data?.Mitarbeiterschulungen?.Hygiene} />
            <CardContent type="JaNein" title="Hygienemaßnahmen an Handwaschplätzen" data={props.data?.Hygienemassnahmen?.Massnahmen_Haendewaschplatz} />
            <CardContent type="JaNein" title="Regelmäßige Desinfektionen" data={props.data?.Hygienemassnahmen?.Routine_Desinfektion} />
            <CardContent tab={1} type="JaNein" title="An Flächen mit Hautkontakt" text={"kein feld"} />
            <CardContent tab={1} type="JaNein" title="An Flächen für antiseptisches Arbeiten" data={props.data?.Hygienemassnahmen?.Routine_Desinfektion?.Aseptische_Flaechen} />
            <CardContent tab={1} type="JaNein" title="In Bereichen mit besonderem Infektionsrisiko" data={props.data?.Hygienemassnahmen?.Routine_Desinfektion?.Fussboden_mit_hohem_Infektionsrisiko} />
            <CardContent tab={1} type="JaNein" title="In Bereichen mit Patientenkontakt" data={props.data?.Hygienemassnahmen?.Routine_Desinfektion?.Patientennahe_Flaechen} />
          </ContentCard>
        </>
      </SectionHeader>
    </>
  )
}

export default Patientensicherheit