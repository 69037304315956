import { Col } from 'react-bootstrap';

interface TextProps {
  text?: string | undefined;
}

const TextLine = (props: TextProps) => {
  return (
    <Col>
      {props.text}
    </Col>
  )
}

export default TextLine
