import { Col, Container, Row } from "react-bootstrap"
import { ToCType } from "../../../types/Types"
import useGenerateToC from "../../hooks/useGenerateToC"

const ToC = () => {
  const headings = useGenerateToC() as ToCType[]

  const openSectionHeader = (id: number | string) => {
    let sectionHeader = Array.from(document.querySelectorAll(`#${id}`)) as HTMLDivElement[]
    if (sectionHeader.length > 0) {
      sectionHeader[1].click();
    }
  }

  return (
    <>
      <Container className="toc">
        {headings.length > 0 && (
          <>
            <Row>
              <Col>Inhaltsverzeichnis</Col>
            </Row>
            {headings.map(heading =>
              <Row key={heading.id}>
                <Col className="linkContainer">
                  <a href={`#${heading.id}`} onClick={() => openSectionHeader(heading.id)}>{heading.title}</a>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export default ToC
