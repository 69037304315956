import { Col, Container, Row } from "react-bootstrap";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { lauer } from '../../../json/Specialist_Lauer';
import { sardoschau } from '../../../json/Specialist_Sardoschau';
import { wagner } from '../../../json/Specialist_Wagner';
import { engstler } from '../../../json/Specialist_Engstler';
import { baumhaekel } from '../../../json/Specialist_Baumhaekel';
import { SpecialistType } from "../../../types/Types";
import TitleBar from "../components/TitleBar";
import Kontakt from "./Kontakt";
import Leistungen from "./Leistungen";
import UeberMich from "./UeberMich";
import Vita from "./Vita";
import * as Constants from '../../../Constants';

export const loader = async ({ params }: LoaderFunctionArgs): Promise<SpecialistType> => {

  const specialist = await fetch(Constants.SERVICEURL + 'specialist/' + params.specialistId)
    .then(response => response.json());

  if (specialist.id === 1652) {
    specialist.json = sardoschau
  }
  if (specialist.id === 4763) {
    specialist.json = wagner
  }
  if (specialist.id === 1668) {
    specialist.json = lauer
  }
  if (specialist.id === 4818) {
    specialist.json = engstler
  }
  if (specialist.id === 2093) {
    specialist.json = baumhaekel
  }

  return specialist;
}

const Specialist = () => {

  const specialist = useLoaderData() as SpecialistType

  return (
    <>
      <TitleBar title={specialist.title} />
      <Container>
        <Row>
          <Col>
            <UeberMich data={specialist.json} name={specialist.title} />
            <Leistungen data={specialist.json} />
            <Vita data={specialist.json} />
            <Kontakt data={specialist.json} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Specialist
