import 'material-symbols';

interface IconProps {
  icon: string;
  variant?: 'outlined' | 'rounded' | 'sharp';
  [key: string]: any
}

const Icon = (props: IconProps) => {
  const variant = props.variant ? props.variant : 'outlined'
  return (
    <span className={`material-symbols material-symbols-${variant}`} {...props}>{props.icon}</span>
  )
}

export default Icon
