import { JSXElementConstructor, ReactElement, useState } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap'
import Icon from './Icon';

interface SectionHeaderProps {
  title: string;
  children: ReactElement<any, string | JSXElementConstructor<any>>
}

const SectionHeader = (props: SectionHeaderProps) => {
  const [isContentOpen, setIsContentOpen] = useState<boolean>(false)

  const openContent = () => {
    setIsContentOpen(!isContentOpen)
  }

  return (
    <>
      <Row>
        <Col>
          <div
            onClick={openContent}
            aria-controls="sectionContent"
            aria-expanded={isContentOpen}
            className="sectionHeader valign-wrapper"
          >
            <h4>{props.title}</h4>
            {isContentOpen ? (
              <Icon icon="do_not_disturb_on" />
            ) : (
              <Icon icon="add_circle" />
            )}

          </div>
        </Col>
      </Row>
      <Collapse in={isContentOpen}>
        <div id="sectionContent">
          {props.children}
        </div>
      </Collapse>
    </>
  )
}

export default SectionHeader
