import { elements } from "../../../types/Transparenzbericht";
import CardContent from "../components/Card/CardContent";
import ContentCard from "../components/ContentCard";
import SectionHeader from "../components/SectionHeader";
import Spezialisten from "./Spezialisten";

interface KompetenzProps {
  data: elements.kompetenzen.Kompetenzen | undefined,
  strukturData: elements.strukturdaten.Strukturdaten | undefined,
}

const Kompetenz = (props: KompetenzProps) => {
  return (
    <>
      <SectionHeader title="B &ndash; Kompetenzspektrum">
        <>
          <ContentCard
            header="Spezialisten"
          >
            <Spezialisten data={props.data?.Spezialisten} />
          </ContentCard>
          {/* <ContentCard
            header="Qualifikationen"
            hint="Offizielle [BÄK] Facharzt Bezeichnung der Spezialisten in der Einrichtung"
            data={props.data?.Arztqualifikationen?.Hauptqualifikation}
          >
            {props.data?.Arztqualifikationen?.Hauptqualifikation?.vorhanden && (
              <CardContent type="List" text={props.data?.Arztqualifikationen?.Hauptqualifikation?.Bezeichnung} />
            )}
            {props.data?.Arztqualifikationen?.Weiterbildungen?.vorhanden && (
              props.data?.Arztqualifikationen?.Weiterbildungen?.weiterbildungen?.map((quali, index) =>
                <CardContent key={index} type="List" text={quali.Bezeichnung} />
              )
            )}
          </ContentCard> */}
          <ContentCard
            header="Tätigkeitsschwerpunkte und spezielle Leistungsangebote"
            hint="Auf diese Behandlungs- und Therapieoptionen ist die Einrichtung spezialisiert"
            data={props.data?.Taetigkeits_Schwerpunkte}
          >
            {props.data?.Taetigkeits_Schwerpunkte?.vorhanden && (
              props.data?.Taetigkeits_Schwerpunkte?.Taetigkeiten?.map((schwerpunkt, index) =>
                <CardContent key={index} type="List" text={schwerpunkt.Bezeichnung} />
              )
            )}
          </ContentCard>
          <ContentCard
            header="Kooperationen und Partnerschaften"
            data={props.strukturData?.Kooperationen}
          >
            {props.strukturData?.Kooperationen?.vorhanden && (
              props.strukturData?.Kooperationen?.Kooperation?.map((kooperation, index) =>
                <CardContent key={index} type="List" text={kooperation.Name} />
              )
            )}
            {props.strukturData?.Mitgliedschaften?.mitglied?.map((mitgliedschaft, index) =>
              <CardContent key={index} type="Text" text={mitgliedschaft.Name} />
            )}
          </ContentCard>
          <ContentCard
            header="Apparative Ausstattung"
            hint="Sofern vorhanden und relevant für die Behandlung"
            data={props.data?.Apparative_Ausstattung}
            nichtVorhandenText="keine spezielle Apparative Ausstattung vorhanden"
          >
            {props.data?.Apparative_Ausstattung?.vorhanden &&
              props.data.Apparative_Ausstattung.Apparative_Ausstattung?.map((ausstattung, index) => 
                <CardContent key={index} type="List" text={ausstattung.Bezeichnung} />
            )}
          </ContentCard>
          <ContentCard
            header="Auszeichnungen / Zertifikate"
            data={props.strukturData?.Zertifkate}
          >
            {props.strukturData?.Zertifkate?.vorhanden && (
              props.strukturData?.Zertifkate?.zertifikate?.map((zertifikate, index) =>
                <CardContent key={index} type="List" text={zertifikate.Bezeichnung} />
              )
            )}
          </ContentCard>
        </>
      </SectionHeader>
    </>
  )
}

export default Kompetenz
