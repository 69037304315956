import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import * as Constants from '../../../Constants';
import { ArticleType } from "../../../types/Types";

export const loader = async ({ params }: LoaderFunctionArgs): Promise<ArticleType> => {
  let load = false
  const time = new Date().getTime()
  let articleList
  const cache = localStorage.getItem('articles')
  if (cache != null) {
    const json = JSON.parse(cache)
    if (time - json.time > Constants.localStorageCacheTimeInMinutes * 60 * 1000) {
      load = true
    } else {
      articleList = json.articleList
    }
  } else {
    load = true
  }
  load = true
  if (load) {
    articleList = await fetch(Constants.SERVICEURL + 'random/5')
      .then(response => response.json());

    localStorage.setItem('articles', JSON.stringify({ articleList: articleList, time: time }))
  }
  return articleList;
}

const Home = () => {

  const articleList = useLoaderData() as ArticleType[]

  return (
    <Container>
      <Row>
        <Col>
          <h2>Willkommen auf dem Testserver von mediWiki</h2>
          <p>mediWiki ist ein umfassendes öffentliches Register aller Einrichtungen und Spezialisten
          aus dem Gesundheits- und Sozialwesen.</p>
          <h3>Einrichtungen</h3>
          <Link to={`practice/1`}>Chirurgische Gemeinschaftspraxis LASAR</Link><br />
          <Link to={`practice/2`}>Oralchirurgie am Schlossberg</Link><br />
          <Link to={`practice/4`}>Gefäßpraxis Dr. Baumhäkel</Link><br />
          <br />
          <h3>Spezialisten</h3>
          <Link to={`specialist/1668`}>Dr. Andreas Lauer</Link><br /> 
          <Link to={`specialist/4818`}>Dr. Barbara Engstler</Link><br />
          <Link to={`specialist/2093`}>Dr. Magnus Baumhäkel</Link><br />
          <br />
          <h3>Ausgewählte Einträge zu Gesundheitsinformationen</h3>
          {articleList.map(art => <Fragment key={art.id}>
            <Link to={`article/${art.id}`}>{art.title}</Link><br />
          </Fragment>)} 
        </Col>
      </Row>
    </Container>
  )
}

export default Home
